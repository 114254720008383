import React from 'react';
import { Loader } from 'components/layout/Loader';

export const Fallback = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
    }}
  >
    <Loader />
  </div>
);

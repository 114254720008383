import { typography } from '../typography';

const common = {
  ...typography.text.caption2,
  letterSpacing: '0px',
  textTransform: 'none',
  borderRadius: 'chip',
  flexShrink: 0,
  py: 1,
  px: 2,
};

const commonSolid = {
  ...common,
  color: 'background.dark',
};

const commonOutline = {
  ...common,
  bg: 'transparent',
  border: '1px solid',
};

export const chips = {
  primary: {
    ...commonSolid,
    bg: 'text.primary',
  },
  blue: {
    ...commonSolid,
    bg: 'info.main',
  },
  green: {
    ...commonSolid,
    bg: 'success.main',
  },
  orange: {
    ...commonSolid,
    bg: 'warning.main',
  },
  pink: {
    ...commonSolid,
    bg: 'var(--blue-main)',
  },
  outline: {
    ...commonOutline,
    color: 'text.primary',
    borderColor: 'var(--grey-3)',
  },
  outlineBlue: {
    ...commonOutline,
    color: 'info.main',
    borderColor: 'info.light',
  },
  outlineGreen: {
    ...commonOutline,
    color: 'success.main',
    borderColor: 'success.main',
  },
  outlineOrange: {
    ...commonOutline,
    color: 'warning.main',
    borderColor: 'warning.main',
  },
  outlinePink: {
    ...commonOutline,
    color: 'var(--blue-main)',
    borderColor: 'var(--blue-main)',
  },
};

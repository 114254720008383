import React from 'react';
import styled from '@emotion/styled/macro';
import { Flex, FlexProps } from 'rebass';
import { Icon } from 'components/primitive/Icon';
import { mdiLock, mdiLockOpen } from '@mdi/js';

import { Link } from 'components/primitive/Link';
import { Text } from 'components/primitive/Text';
import { useRouter } from 'hooks/useRouter';
import { matchPath } from 'react-router-dom';

import { currentUser } from 'utils/currentUser';

type NavTabsLinkObj = {
  url: string;
  route: string | string[];
  label: string;
  // eslint-disable-next-line
  rule?: (matched: any) => boolean;
  index?: boolean;
  strict?: boolean;
  exact?: boolean;
  hide?: boolean;
};

export interface NavTabsProps extends Omit<FlexProps, BrokenRebassProps> {
  options: NavTabsLinkObj[];
  indexUrl: string;
}

const NavTabsLinkContainer = styled(Flex)`
  position: relative;
  align-items: center;
  flex-shrink: 0;
  height: 2.75em;
  padding: 0 1.5em;
  flex-wrap: nowrap;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-image: linear-gradient(
      to bottom left,
      var(--darwin-blue),
      var(--darwin-aqua)
    );
  }
  &.active {
    &::after {
      transition: 400ms ease-in;
      width: 100%;
    }
  }
`;

const NavTabsContainer = styled(Flex)`
  align-items: center;
  flex-wrap: 'nowrap';
  list-style: none;
  margin: 0;
  padding: 0;
`;

const isActive = (
  { route, index, exact, strict, rule }: NavTabsLinkObj,
  pathname: string,
  indexUrl: string
): boolean => {
  const _pathname = pathname !== '/' ? pathname.replace(/\/$/, '') : pathname;
  const match = matchPath(_pathname, { exact, strict, path: route });
  const matchesIndex = _pathname === indexUrl && !!index;
  let passesRule = true;

  if (rule && match) {
    passesRule = rule(match);
  } else if (rule) {
    passesRule = false;
  }

  return (passesRule || matchesIndex) && !!match;
};

const getLinkColor = (
  option: NavTabsLinkObj,
  pathname: string,
  indexUrl: string
) => (isActive(option, pathname, indexUrl) ? 'text.primary' : 'text.main');

export const NavTabs = React.memo(
  ({ options, indexUrl, ...props }: NavTabsProps) => {
    const { pathname } = useRouter();
    return (
      <NavTabsContainer as="ul" {...props}>
        {options.map((option) => {
          const active = isActive(option, pathname, indexUrl)
            ? 'active'
            : undefined;
          const showLock =
            (option.label == 'Event Logs' || option.label == 'Groups') &&
            !currentUser().isPro;
          if (!option?.hide)
            return (
              <NavTabsLinkContainer key={option.url} as="li" className={active}>
                <Link tabIndex={0} to={option.url}>
                  <Text
                    id={`navbar-${option.label}`}
                    variant="h6"
                    color={getLinkColor(option, pathname, indexUrl)}
                  >
                    {option.label}
                    {showLock && (
                      <>
                        <Icon
                          path={active ? mdiLockOpen : mdiLock}
                          style={{
                            opacity: active ? 0.8 : 0.5,
                            marginLeft: '8px',
                            position: 'relative',
                            top: '3px',
                          }}
                          size={0.7}
                        />
                      </>
                    )}
                  </Text>
                </Link>
              </NavTabsLinkContainer>
            );
        })}
      </NavTabsContainer>
    );
  }
);

// Utilities for pkce such as dealing with challenge codes

import { base64UrlSafeEncode } from './base64';
import { generateRandomString } from './random';

/**
 * Validate a challenge code using the given code verifier
 *
 * @param challengeCode The challenge code to validate
 * @param codeVerifier The verifier to use
 * @returns true if the code challenge validates, false otherwise
 */
export async function validateChallengeCode(
  challengeCode: string,
  codeVerifier: string
): Promise<boolean> {
  const buffer = new TextEncoder().encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  it; // URL safe base64 encode, which for some reason browsers still don't supply.
  // Ugh. No good thirdparty libs either
  const challengeCodeFromVerifier = base64UrlSafeEncode(new Uint8Array(digest));
  return challengeCodeFromVerifier == challengeCode;
}

/**
 * Generate a random code verifier and url safe base64 challenge for use in PKCE
 * @param length The length of the code verifier to generate
 */
export async function generateCodeVerifierAndChallengeCode(
  length: number
): Promise<{ codeVerifier: string; challengeCode: string }> {
  const codeVerifier = generateRandomString(length);
  const buffer = new TextEncoder().encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  // URL safe base64 encode, which for some reason browsers still don't
  // supply. Ugh. No good thirdparty libs either
  const challengeCode = base64UrlSafeEncode(new Uint8Array(digest));

  return {
    codeVerifier,
    challengeCode,
  };
}

import { Theme } from 'theme-ui';

import { avatars } from './variants/avatars';
import { banners } from './variants/banners';
import { buttons } from './variants/buttons';
import { cards } from './variants/cards';
import { chips } from './variants/chips';
import { colors, IColorsType } from './colors';
import { dropdowns } from './variants/dropdowns';
import { impact } from './variants/impact';
import { inputs } from './variants/inputs';
import { IRadiiScaleArray, radii } from './radii';
import { IspaceScaleArray, space } from './space';
import { tooltips } from './variants/tooltips';
import { typography } from './typography';

const variants = {
  avatars,
  banners,
  cards,
  chips,
  dropdowns,
  impact,
  inputs,
  tooltips,
};

type PatchedTheme = {
  buttons: typeof buttons;
  colors: IColorsType;
  variants: typeof variants;
  space: IspaceScaleArray;
  radii: IRadiiScaleArray;
  breakpoints: Array<string | number>;
};

type Typography = typeof typography;

// Our custom theme doesn't match the theme-ui types so we need to patch it
export type PhylumUiTheme = Omit<
  Theme,
  'colors' | 'buttons' | 'space' | 'radii' | 'fonts' | 'breakpoints'
> &
  PatchedTheme &
  Typography;

export const theme: PhylumUiTheme = {
  space,
  breakpoints: [0, '40em', '54em', '75em', '87.5em', '103.125em', '108.125em'],
  radii,
  colors,
  buttons,
  variants: variants,
  ...typography,
};

import { useApi } from 'hooks/useApi';
import React from 'react';
import { SWRConfig } from 'swr';

export const SWRConfigContext = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const api = useApi();

  return (
    <SWRConfig
      value={{
        fetcher: async (url: string) => {
          return await api.get(url);
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

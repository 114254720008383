import React from 'react';
import { LatestUpdatesButton } from 'components/layout/Header/styled';
import { Text } from 'components/primitive/Text';
import { DateTime } from 'luxon';
import { Link } from 'components/primitive/Link';
import { ROUTES } from 'routes';

export const LatestUpdates = ({
  startDate = '2023-05-05',
  endDate = '2023-05-12',
  updateUrl = `${ROUTES.EXTERNAL.DOCS}/home/changelog#2023-weeks-13-18`,
  cta = '🚀 New changelog updates!',
}) => {
  const inputValid =
    DateTime.fromISO(startDate).isValid && DateTime.fromISO(endDate).isValid;

  if (!inputValid) {
    console.info('Hey, make sure the dates you entered are valid!');
    return <></>;
  }

  const today = DateTime.now().toISODate();
  const [visibilityOn, setVisibility] = React.useState(
    today >= startDate && today <= endDate
  );

  const recordVisit = () => {
    localStorage.setItem('updatesChecker', today);
    setVisibility(false);
  };

  React.useEffect(() => {
    const lastVisit = localStorage.getItem('updatesChecker');
    const isLastValid = lastVisit && DateTime.fromISO(lastVisit).isValid;
    if (visibilityOn && lastVisit && isLastValid) {
      setVisibility(!(lastVisit >= startDate && lastVisit <= endDate));
    }
  }, []);

  if (visibilityOn && updateUrl.length)
    return (
      <LatestUpdatesButton px="1em" mt="0" mr="1.5em">
        <Link _blank noopener href={updateUrl}>
          <Text
            variant="h6"
            color="text.link"
            fontSize="12px"
            onClick={recordVisit}
          >
            {cta}
          </Text>
        </Link>
      </LatestUpdatesButton>
    );
  else return <></>;
};

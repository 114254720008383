const common = {
  borderRadius: 'circle',
  flexShrink: 0,
};

export const avatars = {
  primary: {
    ...common,
    width: '24px',
    height: '24px',
    minWidth: '24px',
    minHeight: '24px',
    maxWidth: '24px',
    maxHeight: '24px',
  },
  medium: {
    ...common,
    width: '35px',
    height: '35px',
    minWidth: '35px',
    minHeight: '35px',
    maxWidth: '35px',
    maxHeight: '35px',
  },
  large: {
    ...common,
    width: '48px',
    height: '48px',
    minWidth: '48px',
    minHeight: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
  },
};

import React from 'react';
import { Input } from './search/Input';
import { Flex } from 'components/primitive/Flex';
import { Text } from 'components/primitive/Text';
import { ScopeSetter, EcosystemSetter, HintSetter } from './search/Clickables';
import { onKeyboardSubmit } from 'utils/onKeyboardSubmit';
import { useRouter } from 'hooks/useRouter';
import { useSearch } from 'contexts/SearchContext';
import {
  MX,
  ML,
  W,
  validEcosystems,
  searchScopes,
} from './search/helper/constants';
import { hintPrompt, processSearchInput } from './search/helper/utils';

export const SearchBar = () => {
  const { push } = useRouter();
  const context = useSearch();

  const [searchText, setSearchText] = React.useState('');
  const [searchHint, setSearchHint] = React.useState<string | null>(null);
  const [searchScope, setSearchScope] = React.useState<SearchScope | undefined>(
    undefined
  );
  const [ecosystem, setEcosystem] = React.useState<Ecosystem | null>(null);
  const [CVE, setCVE] = React.useState(false);
  const [version, setVersion] = React.useState(false);

  const [searchBarState, setInputState] = React.useState('idle' as InputState);
  const [repeatSearch, setRepeatSearch] = React.useState(-1);

  const resetSearch = (resetOnly = false) => {
    setSearchText('');
    setSearchHint(null);
    setEcosystem(null);
    if (resetOnly) setInputState('active');
    else {
      setSearchScope(undefined);
      setInputState('idle');
    }
  };

  React.useEffect(() => {
    if (searchBarState == 'selected') {
      const timestamp = new Date();
      const searchLink = processSearchInput(
        searchText,
        ecosystem,
        searchScope
      )?.concat(`&time=${timestamp.getMilliseconds()}`);

      if (searchLink) {
        context.PhylumPackages.update(undefined);
        context.Projects.update(undefined);
        context.ProjectPackages.update(undefined);
      }
      searchLink ? push(searchLink) : setInputState('active');
    }
  }, [
    searchBarState,
    repeatSearch,
    searchScope,
    searchHint,
    ecosystem,
    version,
    CVE,
  ]);

  return (
    <>
      {searchBarState == 'active' && (
        <Flex
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
          }}
          width="100vw"
          height="100vh"
          backgroundColor="var(--grey-1)"
          opacity="0.4"
          onClick={() => resetSearch()}
        />
      )}
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        sx={
          searchBarState == 'active'
            ? {
                position: 'absolute',
                left: MX,
                right: MX,
                top: [0, 0, 0, 0, '0.75em'],
              }
            : {
                position: 'absolute',
                left: ML,
                right: '300px',
                top: [0, 0, 0, 0, '0.75em'],
              }
        }
      >
        <Flex maxWidth="1260px" width={W}>
          <Flex
            style={{
              border: '1px solid',
              borderRadius: '3px',
              borderColor:
                searchBarState == 'active' ? 'var(--grey-2)' : 'transparent',
            }}
            px="1.25em"
            py="1em"
            backgroundColor={
              searchBarState == 'active' ? 'var(--grey-2)' : 'var(--grey-1)'
            }
            flexDirection="column"
            alignItems="flex-start"
            width={searchBarState == 'idle' ? '500px' : '100%'}
          >
            <Flex width="100%" alignItems="center">
              <Input
                scope={searchScope || undefined}
                hint={searchHint}
                setVersion={version}
                setCVE={CVE}
                value={searchText}
                state={searchBarState}
                placeholder={
                  searchBarState == 'idle' ? 'search packages & projects' : ''
                }
                onChange={(e) => {
                  const _val = (e.target as HTMLInputElement).value;
                  if (_val == '') setInputState('active');
                  setSearchText((e.target as HTMLInputElement).value);
                }}
                onClear={() => {
                  resetSearch(true);
                }}
                onExit={() => {
                  resetSearch();
                }}
                onCategoryClear={() => {
                  setSearchScope(undefined);
                  setSearchHint(null);
                  setInputState('active');
                  document.getElementById('enter-input')?.focus();
                }}
                onHintReady={(arg) => {
                  if (arg == 'version') setVersion(false);
                  if (arg == 'CVE') setCVE(false);
                }}
                onKeyDown={onKeyboardSubmit(() => {
                  if (searchText) {
                    setRepeatSearch(repeatSearch * -1);
                    setInputState('selected');
                    document.getElementById('enter-searchterm')?.blur();
                  } else if (ecosystem && searchScope == 'Projects') {
                    setRepeatSearch(repeatSearch * -1);
                    setInputState('selected');
                    document.getElementById('enter-searchterm')?.blur();
                  }
                }, ['Enter'])}
                onClick={() => {
                  if (searchBarState == 'idle') {
                    setInputState('active');
                  }
                }}
                pb="2px"
              />
            </Flex>
            {searchBarState == 'active' && (
              <Flex
                minHeight="150px"
                pb="1.5em"
                pt="1.25em"
                px="1.25em"
                flexDirection="column"
              >
                <Text variant="caption1">{hintPrompt(searchScope)}</Text>
                {searchScope == null && (
                  <Flex mt="2em">
                    {searchScopes.map((item) => (
                      <ScopeSetter
                        scope={item}
                        action={() => {
                          setSearchScope(item);
                          setEcosystem(null);
                        }}
                        key={item}
                      />
                    ))}
                  </Flex>
                )}

                {searchScope == 'PhylumPackages' && (
                  <Flex mt="2.25em">
                    {validEcosystems.map((item) => (
                      <EcosystemSetter
                        newSelection={item}
                        action={() => {
                          setEcosystem(item);
                          setSearchHint(`eco: ${item}`);
                        }}
                        currentSelection={ecosystem}
                        key={item}
                      />
                    ))}
                  </Flex>
                )}
                {searchScope == 'ProjectPackages' && (
                  <Flex mt="2em" flexDirection="column">
                    <HintSetter
                      hint="version"
                      action={() => setVersion(true)}
                    />
                    <HintSetter
                      hint="packageCVE"
                      action={() => {
                        setSearchHint(null);
                        setSearchText('');
                        setCVE(true);
                      }}
                    />
                  </Flex>
                )}
                {searchScope == 'Projects' && (
                  <Flex mt="2.25em">
                    {validEcosystems.map((item) => (
                      <EcosystemSetter
                        newSelection={item}
                        action={() => {
                          setEcosystem(item);
                          setSearchHint(`eco: ${item}`);
                        }}
                        currentSelection={ecosystem}
                        key={item}
                      />
                    ))}
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

import { typography } from '../typography';

const common = {
  container: {
    maxHeight: '2.56em',
  },
  head: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    bg: 'var(--grey-1)',
    color: 'var(--white-1)',
  },
  list: {
    bg: 'var(--grey-1)',
    borderRadius: '3px',
    py: '0.3em',
    px: '1em',
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  text: {
    ...typography.text.body2,
    border: '1px solid var(--grey-4)',
    color: 'var(--white-1)',
  },
  option: {
    py: '0.2em',
    px: 0,
    userSelect: 'none',
    borderRadius: '3px',
    '.optionList': {
      '.optionInfo': {
        position: 'relative',
        top: '3px',
      },
    },
  },
  icon: {
    //this seems to do nothing
    color: 'var(--white-3)',
    size: 0.9,
  },
};
/* eslint-disable @typescript-eslint/no-explicit-any */
export const dropdowns: Record<string, any> = {
  primary: {
    container: {
      ...common.container,
    },
    head: {
      ...common.head,
      color: 'var(--green-highlight)',
    },
    list: {
      ...common.list,
      boxShadow: `0px 2px 6px 2px var(--grey-1)`,
      maxHeight: '175px',
    },
    text: {
      ...common.text,
      color: 'var(--white-1)',
      width: '100%',
      fontSize: '14px',
      border: '1px solid transparent',
    },
    option: {
      ...common.option,
      py: '0.2em',
    },
    icon: {
      ...common.icon,
    },
  },
  tiny: {
    container: {
      maxHeight: '1em',
      pb: '10em',
    },
    head: {
      ...common.head,
      bg: 'transparent',
    },
    list: {
      ...common.list,
      border: '1px solid',
      borderColor: 'var(--grey-3)',
      bg: 'var(--grey-1)',
      pt: '0.15em',
      pb: '0.75em',
      color: 'var(--white-2)',
      marginBottom: '3em',
    },
    text: {
      ...common.text,
      ...typography.text.caption2,
      letterSpacing: '0.5px',
      border: '1px solid transparent',
      color: 'var(--white-3)',
    },
    option: {
      ...common.option,
      pl: '0.2em',
      py: '0.075em',
      '&:hover': {
        opacity: 0.5,
      },
    },
    icon: {
      ...common.icon,
      opacity: 0.6,
    },
  },
  outline: {
    container: {
      ...common.container,
    },
    head: {
      ...common.head,
      bg: 'transparent',
    },
    list: {
      ...common.list,
      border: '1px solid',
      borderColor: 'var(--grey-3)',
      bg: 'var(--grey-1)',
    },
    text: {
      ...common.text,
    },
    option: {
      ...common.option,
      pl: '0.2em',
    },
    icon: {
      ...common.icon,
    },
  },
  multi: {
    container: {
      ...common.container,
    },
    head: {
      ...common.head,
      py: '0.9em',
    },
    list: {
      ...common.list,
      pt: '0.5em',
      px: '1em',
      boxShadow: `0px 2px 6px 2px var(--grey-1)`,
      bg: 'var(--grey-3)',
      position: 'absolute',
      left: 'initial',
      right: 0,
    },
    text: {
      ...common.text,
      color: 'var(--white-1)',
      width: '100%',
    },
    option: {
      ...common.option,
      py: '0.4em',
    },
    icon: {
      ...common.icon,
    },
  },
};

const common = {
  alignItems: 'center',
  borderRadius: '3px',
  border: '1px solid',
  boxSizing: 'border-box',
  display: 'inline-flex',
  fontFamily: 'Manrope',
  fontWeight: 600,
  justifyContent: 'center',
  textTransform: 'uppercase',
  letterSpacing: '0.75px',
  minWidth: 'fit-content',
  mr: '0.125em',
};

const primary = {
  ...common,
  fontSize: '10px',
  height: '18px',
  lineHeight: '12px',
  minHeight: '18px',
  maxHeight: '18px',
  padding: '1px 6px',
};

const secondary = {
  ...common,
  fontSize: '9px',
  flexShrink: 0,
  height: '16px',
  lineHeight: '9px',
  minHeight: '16px',
  maxHeight: '16px',
  padding: '0px 4px',
};

export const impact = {
  critical: {
    ...primary,
    bg: 'var(--grey-1)',
    color: 'var(--impact-critical)',
    borderColor: 'var( --impact-critical-border)',
  },

  high: {
    ...primary,
    bg: 'var(--pink-bg)',
    color: 'impact.high',
    borderColor: 'var(--pink-border)',
  },

  medium: {
    ...primary,
    bg: 'var(--yellow-bg)',
    color: 'impact.medium',
    borderColor: 'var(--yellow-border)',
  },

  low: {
    ...primary,
    bg: 'var(--green-bg)',
    color: 'impact.low',
    borderColor: 'var(--green-border)',
  },

  ignored: {
    ...secondary,
    bg: 'var(--grey-1)',
    color: 'text.main',
    borderColor: 'var(--grey-4)',
  },

  rejected: {
    ...secondary,
    bg: 'transparent',
    color: 'error.main',
    borderColor: '#F20B7860',
  },

  info: {
    ...secondary,
    bg: 'transparent',
    color: 'blue.main',
    borderColor: 'var(--blue-border)',
  },
};

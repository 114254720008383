import React from 'react';
import styled from '@emotion/styled/macro';
import { Text } from 'components/primitive/Text';
import { Box } from 'components/primitive/Box';
import { Flex } from 'rebass';
import { onKeyboardSubmit } from 'utils/onKeyboardSubmit';

export interface SwitchProps extends React.ComponentProps<'input'> {
  name?: string;
  isSmall?: boolean;
}

const StyledSwitch = styled(Flex)`
  position: relative;
  transition: 200ms ease-in-out;
  border: 1px solid var(--white-3);
  &.disabled {
    border: 1px solid var(--grey-3);
  }
  transform-origin: 50% 50%;
  transform: scale(0);
  animation: 300ms switch-appear 0ms ease-in-out 1 forwards;
  @keyframes switch-appear {
    0% {
      transform: scale(0);
    }
    90% {
      transform: scale(1.15);
    }
    95% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  &::before {
    content: '';
    position: absolute;
    border-radius: 100%;
    background: var(--white-2);
    transition: 150ms ease-in;
    transform-origin: 50% 50%;
    transform: scale(0);
    animation: 200ms switch-appear 300ms ease-in-out 1 forwards;
  }
  &.default {
    border-radius: 2em;
    height: 1.4375em;
    min-height: 1.4375em;
    max-height: 1.4375em;
    width: 2.5em;
    min-width: 2.5em;
    max-width: 2.5em;
    margin-right: 11px;
  }
  &.tiny {
    margin-top: 2px;
    border-radius: 1.5em;
    height: 0.958em;
    min-height: 0.958em;
    max-height: 0.958em;
    width: 1.66em;
    min-width: 1.66em;
    max-width: 1.66em;
    margin-right: 7.5px;
  }
  &.default::before {
    height: 1em;
    width: 1em;
    top: 0.165em;
    left: 0.165em;
  }
  &.tiny::before {
    height: 0.725em;
    width: 0.725em;
    top: 0.05em;
    left: 0.05em;
  }
`;
const StyledInput = styled.input`
  position: absolute;
  max-width: 0px;
  z-index: -100;
  opacity: 0;
  &:checked + div {
    border: 1px solid var(--blue-border);
  }
  &:checked + div::before {
    background: var(--blue-main);
  }

  &.disabled:checked + div {
    border: 1px solid var(--grey-4);
  }
  &.disabled:checked + div::before {
    background: var(--grey-4);
  }
  &.default:checked + div::before {
    left: calc(100% - 0.8125em - 0.33em);
  }
  &.tiny:checked + div::before {
    left: calc(100% - 0.8125em - 0.05em);
  }
`;

export const Switch = ({
  color = 'text.primary',
  name: nameProp,
  isSmall = false,
  children,
  ...props
}: SwitchProps) => {

  let _className = isSmall ? 'tiny' : 'default';
  _className = props.disabled ? _className + ' disabled' : _className;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const name = React.useRef<string>(nameProp || Math.random().toString());
  return (
    <Flex
      as="label"
      alignItems="center"
      role="checkbox"
      tabIndex={0}
      aria-checked={!!inputRef.current?.checked}
      onKeyDown={onKeyboardSubmit(() => inputRef.current?.click())}
      style={{ ...props.style, cursor: 'pointer' }}
    >
      <StyledInput
        {...props}
        ref={inputRef}
        type="checkbox"
        tabIndex={0}
        id="toggleSwitch"
        className={_className}
      />
      <StyledSwitch className={_className} />
      {!children ? null : typeof children !== 'string' ? (
        <Box id={name.current}>
          <>{children}</>
        </Box>
      ) : (
        <Text id={name.current} sx={{ color }}>
          {children}
        </Text>
      )}
    </Flex>
  );
};

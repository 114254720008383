import React from 'react';
import { useRouter } from 'hooks/useRouter';

export const Authorize = (props: { authState: AuthState }): JSX.Element => {
  const { query } = useRouter();
  const { idp } = query;
  React.useEffect(() => {
    if (props.authState.state === 'needAuthorization') {
      const authUrl = new URL(props.authState.authorizationUrl);
      if (idp) {
        authUrl.searchParams.set('kc_idp_hint', idp);
      }
      window.location.href = authUrl.href;
    }
  }, [props.authState, idp]);
  return <></>;
};

import React from 'react';
import styled from '@emotion/styled/macro';
import { Button as ReButton } from 'rebass';
import { ButtonProps } from 'components/simple/Button/Button';
import { Flex } from 'components/primitive/Flex';
import { mdiClose } from '@mdi/js';
import { Icon } from 'components/primitive/Icon';
import { Text } from 'components/primitive/Text';
import { categoryDict, hintsDict } from './helper/constants';

const StyledRebutt = styled(ReButton)`
  background-color: var(--grey-3);
  border-radius: 2px;
  cursor: pointer;
  > span > svg {
    opacity: 0.2 !important;
  }
  &:hover {
    background-color: var(--grey-2);
    > span > svg {
      opacity: 1 !important;
    }
  }
  &.notSelected {
    opacity: 0.5;
  }
`;

const ButtonText = styled(Text)`
  position: relative;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  white-space: nowrap;
`;

const CategoryButton = ({
  as = 'button',
  variant = 'searchHint',
  children,
  sx,
  ...props
}: ButtonProps) => {
  return (
    <StyledRebutt
      mr="0.5em"
      mb="0.5em"
      px="1.5em"
      {...{ as, variant, ...props }}
      sx={{
        ...sx,
      }}
    >
      <ButtonText sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
        {children}
      </ButtonText>
    </StyledRebutt>
  );
};

const HintButton = ({
  as = 'button',
  variant = 'searchHint',
  children,
  ...props
}: ButtonProps) => {
  const opacity =
    props.className && (children as string).toLowerCase() != props.className
      ? 0.5
      : 1;
  return (
    <StyledRebutt
      {...{ as, variant, ...props }}
      minWidth="fit-content"
      height="1.75em"
      px="0.75em"
      pb="8px"
      opacity={opacity}
    >
      <ButtonText sx={{ fontSize: '14px' }} opacity={opacity}>
        {children}
      </ButtonText>
    </StyledRebutt>
  );
};

export const ScopeLabel = ({
  as = 'button',
  variant = 'searchHint',
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledRebutt
      {...{ as, variant, ...props }}
      minWidth="fit-content"
      height="24px"
      px="0.75em"
      pb="8px"
      mr="1em"
    >
      <ButtonText mr="0.5em" sx={{ fontSize: '14px' }}>
        {children}
      </ButtonText>
      <Icon size={0.7} path={mdiClose} sx={{ height: '1em', width: '1em' }} />
    </StyledRebutt>
  );
};

export const HintSetter = ({
  hint,
  action,
}: {
  hint: string;
  action: () => void;
}) => {
  return (
    <Flex alignItems="center" mb="0.5em">
      <HintButton
        mr="1.5em"
        onClick={() => {
          action();
          document.getElementById('enter-searchterm')?.focus();
        }}
      >
        {hintsDict[hint].label}
      </HintButton>
      <Text variant="caption2" fontSize="14px">
        {hintsDict[hint].text}
      </Text>
    </Flex>
  );
};

export const EcosystemSetter = ({
  newSelection,
  currentSelection,
  action,
}: {
  newSelection: Ecosystem;
  currentSelection: Ecosystem | null;
  action: () => void;
}) => {
  return (
    <HintButton
      className={currentSelection as string}
      mr="0.5em"
      mb="0.5em"
      onClick={() => {
        action();
        document.getElementById('enter-searchterm')?.focus();
      }}
    >
      {newSelection}
    </HintButton>
  );
};

export const ScopeSetter = ({
  scope,
  action,
}: {
  scope: SearchScope;
  action: () => void;
}) => {
  return (
    <CategoryButton
      onClick={() => {
        action();
        document.getElementById('enter-searchterm')?.focus();
      }}
    >
      {categoryDict[scope]}
    </CategoryButton>
  );
};

export interface IColorsType {
  [category: string]: {
    [shade: string]: string;
  };
}

export const colors: IColorsType = {
  background: {
    main: 'var(--grey-2)',
    dark: 'var(--grey-1)',
    light: 'var(--grey-3)',
  },
  text: {
    primary: 'var(--white-1)',
    main: 'var(--white-2)',
    secondary: 'var(--white-3)',
    link: 'var(--blue-main)',
  },
  error: {
    main: 'var(--pink-main)',
    dark: 'var(--pink-bg)',
  },
  warning: {
    main: 'var(--yellow-main)',
    light: 'var(--yellow-border)',
    dark: 'var(--yellow-bg)',
  },
  success: {
    main: 'var(--green-main)',
    light: 'var(--green-border)',
    dark: 'var(--green-bg)',
  },
  impact: {
    critical: 'var(--impact-critical)',
    high: 'var(--impact-high)',
    medium: 'var(--impact-medium)',
    low: 'var(--impact-low)',
  },
  blue: {
    main: 'var(--blue-main)',
  },
  packageDetailSuccess: {
    main: 'var(--white-2)',
  },
  packageDetailWarning: {
    main: 'var(--white-2)',
  },
  packageDetailInfo: {
    main: 'var(--white-2)',
  },
};

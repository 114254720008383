import React from 'react';

import { Flex, Image } from 'rebass';
import { Box, TextContainer } from 'components/primitive/Box';
import { Text } from 'components/primitive/Text';
import { Link } from 'components/primitive/Link';
import { GraphicsContainer } from 'components/primitive/Box';
import EmptyBoxSVG from 'assets/graphics/contact.svg';

export const ContactSales = () => {
  const emailMessage =
    'Hi, I am interested in upgrading from Phylum Community Account to Phylum Pro.';
  const emailSubject = 'Upgrade to Phylum Pro';

  return (
    <>
      <Text
        variant="h1"
        color="text.primary"
        mt={['2em', '2em', '0.5em']}
        fontSize={['24px', '24px', '32px']}
        display={['block', 'block', 'block', 'none']}
        width="100%"
        textAlign="center"
      >
        Easy upgrade coming soon!
      </Text>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={['1em', '1em', '3em', 0]}
        py={['1em', '2em', '3em', '5em']}
        flexDirection={['column', 'column', 'row']}
      >
        <GraphicsContainer
          width={[1, 1, 0.5]}
          sx={{ maxWidth: '35.6em', marginTop: ['6vh', '6vh', '6vh', '10vh'] }}
        >
          <Image
            width={[0.7, 0.7, 0.7, 1]}
            src={EmptyBoxSVG}
            alt="packages graphic"
          />
        </GraphicsContainer>
        <TextContainer width={[1, 1, 0.5]}>
          <Text
            variant="h1"
            color="text.primary"
            my="0.75em"
            fontSize={[0, 0, 0, '32px']}
            display={['none', 'none', 'none', 'block']}
            data-test="upgrade-headline"
          >
            Easy upgrade coming soon!
          </Text>
          <Box py={['1em', '1em', 0]} textAlign={['center', 'center', 'left']}>
            <Text
              variant="body1"
              sx={{ lineHeight: '1.5em' }}
              data-test="empty"
            >
              In the meantime, please contact{' '}
              <Link
                href={`mailto:sales@phylum.io?&subject=${encodeURIComponent(
                  emailSubject
                )}&body=${encodeURIComponent(emailMessage)}`}
                id="email-to-sales"
              >
                sales@phylum.io
              </Link>{' '}
              to upgrade to Pro. Our friendly sales team will quickly and easily
              assist you.
            </Text>
          </Box>
        </TextContainer>
      </Flex>
    </>
  );
};

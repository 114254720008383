import React from 'react';
import { AxiosInstance } from 'axios';
import { ApiContext } from 'contexts/ApiContext';

// Hook for child components to get the api object.
export function useApi(): AxiosInstance {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error(`useApi must be used within a AuthProvider`);
  }
  return context;
}

import styled from '@emotion/styled/macro';
import { Box } from 'components/primitive/Box';

export const Container = styled.div`
  position: relative;
  width: 100%;
  opacity: 0;
  transform: scale(1);
  animation: 500ms package-search-content-appear 100ms linear 1 forwards;
  @keyframes package-search-content-appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const QuickContainer = styled.div`
  position: relative;
  width: 100%;
  opacity: 0;
  animation: 200ms package-search-content-appear 100ms linear 1 forwards;
  @keyframes package-search-content-appear {
    0% {
      opacity: 0;
      transform: scale(0.99);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const GraphicsContainer = styled(Box)`
  opacity: 0;
  transform: scale(1);
  animation: 800ms package-graphic-appear 0ms ease-in-out 1 forwards;
  filter: grayscale(100%);
  @keyframes package-graphic-appear {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }
`;

export const TextContainer = styled(Box)`
  opacity: 0;
  transform: translate(0, 20px);
  animation: 500ms package-search-content-appear 100ms linear 1 forwards;

  @keyframes package-search-content-appear {
    0% {
      opacity: 0;
      transform: translate(0, 20px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
`;

import { typography } from '../typography';

const common = {
  ...typography.text.h6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: '43px',
  maxHeight: '43px',
  fontWeight: 'bold',
  borderRadius: '3px',
  userSelect: 'none',
  border: '1px solid',
  cursor: 'pointer',
};

const blueCommon = {
  ...common,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  transition: '250ms linear',
  overflow: 'hidden',
  px: 9,
  py: 0,
  bg: 'var(--blue-dark)',
  color: 'var(--white-0)',
  borderColor: 'var(--blue-dark-border)',
  '&:hover': {
    backgroundColor: 'var(--blue-main)',
  },
  '&:active': {
    bg: 'var(--blue-dark)',
    opacity: 0.8,
  },
};

const smallCommon = Object.assign({}, common, typography.text.caption2, {
  fontWeight: 800,
  lineHeight: '10px',
  maxHeight: '24px',
  border: '1px solid',
  borderRadius: '2px',
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
  color: 'var(--white-0)',
  px: '1.5em',
  py: 0,
});

const searchRelated = Object.assign({}, common, typography.text.h6, {
  lineHeight: '10px',
  borderRadius: '1px',
  height: '36px',
  maxHeight: '36px',
});

export const buttons: Record<
  string,
  string | Record<string, string | number | Record<string, string | number>>
> = {
  primary: {
    ...blueCommon,
    height: 'inital',
  },
  primaryDisabled: {
    ...blueCommon,
    height: 'inital',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  outlineBlue: {
    ...common,
    pt: '6px',
    bg: 'transparent',
    borderColor: 'var(--blue-border)',
    color: 'var(--blue-main)',
    '&:hover': {
      borderColor: 'var(--blue-border-hover)',
    },
    '&:active': {
      borderColor: 'var(--blue-dark-bg)',
      opacity: 0.9,
    },
  },
  outlineRed: {
    ...common,
    bg: 'transparent',
    pt: '6px',
    borderColor: 'var(--pink-border)',
    color: 'var(--pink-main)',
    '&:hover': {
      borderColor: 'var(--pink-border-hover)',
    },
    '&:active': {
      borderColor: 'var(--pink-bg)',
    },
  },
  primarySmall: {
    ...blueCommon,
    ...smallCommon,
  },
  outlineSmallBlue: {
    ...smallCommon,
    bg: 'transparent',
    borderColor: 'var(--blue-border)',
    color: 'var(--blue-main)',
    '&:hover': {
      borderColor: 'var(--blue-border-hover)',
    },
    '&:active': {
      borderColor: 'var(--blue-dark-bg)',
    },
  },
  outlineSmallRed: {
    ...smallCommon,
    bg: 'transparent',
    borderColor: 'var(--pink-border)',
    color: 'var(--pink-main)',
    '&:hover': {
      borderColor: 'var(--pink-border-hover)',
    },
    '&:active': {
      borderColor: 'var(--pink-bg)',
    },
  },
  searchHint: {
    ...searchRelated,
    bg: 'var(--grey-3)',
    borderColor: 'var(--grey-3)',
    color: 'text.primary',
    px: 11,
  },
  searchHintSelect: {
    ...searchRelated,
    bg: 'transparent',
    borderColor: 'var(--grey-3)',
    color: 'text.primary',
    px: 11,
  },
};

// Utilities for producing random values

/**
 * Generate a random string of the given length
 *
 * String contains characters from [0-9,a-z,A-Z]
 *
 * @param length The length of string to generate
 * @returns a string of the given length with random characters
 */
export function generateRandomString(length: number): string {
  const safeChars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const safeCharsLen = safeChars.length;
  const ramdomBytes = new Uint8Array(length);
  crypto.getRandomValues(ramdomBytes);
  let randomString = '';
  ramdomBytes.forEach((byte) => {
    byte = byte % safeCharsLen;
    randomString += safeChars[byte];
  });
  return randomString;
}

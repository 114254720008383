export interface ShorthandSizes<T> {
  h1: T;
  h2: T;
  h3: T;
  h4: T;
  h5: T;
  h6: T;
  body1: T;
  body2: T;
  button: T;
  caption1: T;
  caption2: T;
  error: T;
  mono: T;
}

export type ITypographyScaleArray<T> = Array<T> & ShorthandSizes<T>;

export interface ITypography {
  fonts: { [variant: string]: string };
  fontWeights: { [variant: string]: number };
  fontSizes: ITypographyScaleArray<number>;
  letterSpacings: ITypographyScaleArray<number | string>;
  text: { [variant: string]: { [styleProp: string]: string | number } };
}

/**
 * System-UI font-size scale
 */
const fontSizesScale = [10, 12, 14, 16, 18, 20, 22, 28, 32];
const fontSizesScaleObject: ShorthandSizes<number> = {
  h1: fontSizesScale[8],
  h2: fontSizesScale[7],
  h3: fontSizesScale[6],
  h4: fontSizesScale[4],
  h5: fontSizesScale[3],
  h6: fontSizesScale[2],
  body1: fontSizesScale[2],
  body2: fontSizesScale[2],
  button: fontSizesScale[2],
  caption1: fontSizesScale[1],
  caption2: fontSizesScale[0],
  error: fontSizesScale[2],
  mono: fontSizesScale[2],
};
const fontSizes: ITypographyScaleArray<number> = Object.assign(
  {},
  fontSizesScale,
  fontSizesScaleObject
);

/**
 * System-UI letter-spacing scale
 */
const letterSpacingsScale = [0.1, 0.25, 0.5, 'normal'];
const letterSpacingsScaleObject: ShorthandSizes<number | string> = {
  h1: letterSpacingsScale[1],
  h2: letterSpacingsScale[1],
  h3: letterSpacingsScale[1],
  h4: letterSpacingsScale[1],
  h5: letterSpacingsScale[2],
  h6: letterSpacingsScale[2],
  body1: letterSpacingsScale[0],
  body2: letterSpacingsScale[0],
  button: letterSpacingsScale[1],
  caption1: letterSpacingsScale[3],
  caption2: letterSpacingsScale[2],
  error: letterSpacingsScale[2],
  mono: letterSpacingsScale[2],
};
const letterSpacings: ITypographyScaleArray<number | string> = Object.assign(
  {},
  letterSpacingsScale,
  letterSpacingsScaleObject
);

/**
 * Helper function to keep text variant definitions a bit more dry
 */
const createTextVariant = (
  variant: string,
  fontWeight: string,
  opts?: { [styleName: string]: string | number }
) => ({
  fontFamily: 'default',
  fontSize: variant,
  letterSpacing: variant,
  color: 'text.primary',
  px: 0,
  fontWeight,
  ...opts,
});

/**
 * Complete `typography` theme export
 */
export const typography: ITypography = {
  fonts: {
    default: "'Open Sans', sans-serif",
    header: "'Manrope', sans-serif",
    mono: "Monaco, Consolas, Menlo, 'Source Code Pro', monospace",
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 800,
  },
  fontSizes,
  letterSpacings,
  text: {
    h1: createTextVariant('h1', 'semibold', {
      fontFamily: 'header',
    }),
    h2: createTextVariant('h2', 'semibold', {
      fontFamily: 'header',
    }),
    h3: createTextVariant('h3', 'semibold', {
      fontFamily: 'header',
    }),
    h4: createTextVariant('h4', 'semibold', {
      fontFamily: 'header',
    }),
    h5: createTextVariant('h5', 'semibold', {
      fontFamily: 'header',
    }),
    h6: createTextVariant('h6', 'bold', {
      fontFamily: 'header',
    }),
    body1: createTextVariant('body1', 'regular', {
      lineHeight: 1.5,
    }),
    body2: createTextVariant('body2', 'regular', {
      color: 'text.main',
      lineHeight: 1.5,
    }),
    button: createTextVariant('button', 'bold'),
    caption1: createTextVariant('caption1', 'regular', {
      color: 'text.primary',
    }),
    caption2: createTextVariant('caption2', 'regular', {
      color: 'text.main',
    }),
    error: createTextVariant('error', 'regular', {
      color: 'error.main',
    }),
    mono: createTextVariant('mono', 'regular', {
      fontFamily: 'mono',
      color: 'warning.main',
    }),
  },
};

import React from 'react';
import { Image } from 'rebass';
import searchIcon from 'assets/icons/search.svg';
import styled from '@emotion/styled/macro';
import { mdiClose } from '@mdi/js';
import { Icon } from 'components/primitive/Icon';
import { Box, BoxProps } from 'components/primitive/Box/Box';

import { onKeyboardSubmit } from 'utils/onKeyboardSubmit';
import { ScopeLabel } from './Clickables';
import { Text } from 'components/primitive/Text';

const ClearEx = styled(Box)`
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:focus-visible {
    opacity: 0.8;
    outline: none;
  }
`;

const Clear = styled(Box)`
  opacity: 0.5;
  margin-right: 0.5em;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 2px;
  color: var(--white-1);
  &:hover {
    opacity: 0.8;
  }
  &:focus-visible {
    opacity: 0.8;
    outline: none;
  }
`;

export interface InputProps
  extends BoxProps,
    Omit<React.ComponentProps<'input'>, keyof BoxProps | BrokenRebassProps> {
  scope: SearchScope | undefined;
  state: InputState;
  hint: string | null;
  setVersion: boolean;
  setCVE: boolean;
  onClear: () => void;
  onExit: () => void;
  onCategoryClear: () => void;
  onHintReady: (arg: string) => void;
}

export const Input = ({
  scope,
  hint,
  setVersion,
  setCVE,
  state,
  value,
  onChange,
  onClear,
  onExit,
  onCategoryClear,
  onHintReady,
  type = 'text',
  as = 'input',
  ...props
}: InputProps) => {
  const [_value, setValue] = React.useState('');

  React.useEffect(() => {
    // help setting up a useful search combination
    if (scope == 'PhylumPackages') {
      if (_value.includes('CVE-')) handleErase();
    }
    if (setCVE) {
      handleErase();
      setValue('CVE-');
      onHintReady('CVE');
    }
    if (setVersion) {
      if (_value) {
        if (!_value.includes('@') && !_value.includes('CVE-'))
          setValue(_value + '@');
        else if (_value.includes('CVE-')) setValue('@');
      } else setValue('@');
      onHintReady('version');
    }
  }, [value, scope, setVersion, setCVE]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange && onChange(e);
    },
    []
  );

  const handleClear = React.useCallback(() => {
    setValue('');
    onClear();
    document.getElementById('enter-searchterm')?.focus();
  }, []);

  const handleExit = React.useCallback(() => {
    setValue('');
    onExit();
  }, []);

  const handleErase = React.useCallback(() => {
    setValue('');
    document.getElementById('enter-searchterm')?.focus();
  }, []);

  return (
    <Box
      backgroundColor={state == 'idle' ? 'var(--grey-1)' : 'var(--overlay-grey)'} 
      variant="inputs.outlineContainer"
      sx={{ position: 'relative' }}
      width={1}
      height="2.5em"
    >
      {scope ? (
        <ScopeLabel onClick={onCategoryClear}>{scope}</ScopeLabel>
      ) : (
        <Image
          src={searchIcon}
          sx={{ minWidth: '21px', width: '21px' }}
          mr={1}
          opacity={state == 'active' ? 1 : state == 'selected' ? 0.6 : 0.4}
        />
      )}
      <Text
        variant="body1"
        fontSize="14px"
        sx={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}
      >
        {hint}
      </Text>

      <Box
        ml="0.75em"
        {...{ as, ...props }}
        type={type}
        tabIndex={0}
        id="enter-searchterm"
        variant="inputs.outline"
        value={_value}
        onChange={handleChange}
      />

      <Clear
        tabIndex={0}
        style={{
          visibility: _value || hint ? 'visible' : 'hidden',
        }}
        onClick={handleClear}
        onKeyDown={onKeyboardSubmit(handleClear)}
      >
        clear
      </Clear>
      {state != 'idle' && (
        <ClearEx
          variant="inputs.clearIcon"
          tabIndex={0}
          sx={{ height: '20px' }}
          onClick={handleExit}
          onKeyDown={onKeyboardSubmit(handleExit)}
        >
          <Icon size={0.875} path={mdiClose} />
        </ClearEx>
      )}
    </Box>
  );
};

export const searchScopes: SearchScope[] = [
  'ProjectPackages',
  'PhylumPackages',
  'Projects',
];

export const categoryDict: Record<SearchScope, string> = {
  PhylumPackages: 'Packages In Phylum',
  ProjectPackages: 'Packages In Projects',
  Projects: 'Projects'
};

export const hintsDict: Record<string, { label: string; text: string }> = {
  version: {
    label: 'version:',
    text: 'to find a specific version of packages whose name resembles or matches the entered text',
  },
  package: {
    label: 'package:',
    text: 'to find projects that include a package specified by name or name@version',
  },
  packageCVE: {
    label: 'CVE-',
    text: 'to find packages affected by CVE-xxx-xxxx',
  },
};

export const validEcosystems: Ecosystem[] = [
  'pypi',
  'maven',
  'npm',
  'rubygems',
  'nuget',
  'golang',
  'cargo',
];

export const MX = [
  '1vw',
  '1.5vw',
  '1.5vw',
  '1vw',
  '1vw',
  '10vw',
  '12.5vw',
  '220px',
];
export const ML = ['160px', '160px', '160px', '160px', '12.5vw', '220px'];
export const W = ['75vw', '75vw', '60vw', '60vw', '60vw', '60vw'];

import React from 'react';
import { Image, Flex } from 'rebass';
import { Nav } from 'components/layout/Nav';
import { Link } from 'components/primitive/Link';
import { Tooltip } from 'components/layout/Tooltip';
import { Divider } from 'components/primitive/Divider';
import { LatestUpdates } from 'views/App/components/LatestUpdates';
import { SearchBar } from 'views/App/components/SearchBar';
import { useAuth } from 'hooks/useAuth';
import { currentUser } from 'utils/currentUser';
import UserSVG from 'assets/icons/user.svg';
import CommunityLogo from 'assets/logos/phylum-community.svg';
import EnterpriseLogo from 'assets/logos/phylum-pro.svg';
import PhylumLogo from 'assets/logos/phylum-default.svg';
import LinkIcon from 'assets/icons/link-out-blue.svg';
import { Icon } from 'components/primitive/Icon';
import { mdiBookOpenPageVariantOutline } from '@mdi/js';
import { ROUTES } from 'routes';
import { UserLabel, StyledHeader, JoinDiscord } from './styled';
import { NavText, NavLink, NavItem, NavWrap } from './styled';
import { ToggleExperiment } from './styled';
import { env } from 'config/env';
import useSWR from 'swr';

export const Header = () => {
  const authState = useAuth();
  const user = currentUser();
  const { data: githubInstallations } = useSWR(
    `${ROUTES.API.GITHUB_INSTALLATIONS}`
  );

  const loginLink =
    authState.state === 'needAuthorization' ? authState.authorizationUrl : null;

  const AccountInfo: Record<string, Tier> = {
    community: {
      logo: CommunityLogo,
      isFree: true,
      linkLabel: 'Phylum Community',
    },
    pro: {
      logo: EnterpriseLogo,
      isFree: false,
      linkLabel: 'Phylum Pro Account',
    },
    unknown: {
      logo: PhylumLogo,
      isFree: null,
      linkLabel: 'Not Signed In',
    },
  };

  return (
    <>
      <StyledHeader
        id="header"
        as="header"
        height={['72px', '72px', '72px', '72px', '100px']}
      >
        <Link to={ROUTES.DASHBOARD.path}>
          <Image
            src={AccountInfo[user.tier]['logo']}
            sx={{ height: ['40px', '40px', '40px', '40px', '50px'] }}
            alt="Phylum Logo"
          />
        </Link>
        <Flex alignItems="center" sx={{ display: 'flex' }}>
          <LatestUpdates />
          <JoinDiscord
            _blank
            href="https://discord.gg/Fe6pr5eW6p"
            display={['none', 'none', 'none', 'inline']}
          >
            &nbsp;&nbsp;Discord
          </JoinDiscord>
          <Tooltip
            hoverable={false}
            side="bottomright"
            renderTooltip={() => (
              <Flex flexDirection="column" minWidth="21.75em" p="1.5em">
                <NavWrap>
                  <NavItem
                    link={`${ROUTES.EXTERNAL.DOCS}`}
                    text="Welcome to Phylum"
                    isExternal={true}
                    hasDivider={false}
                  />
                  <NavItem
                    link={`${ROUTES.EXTERNAL.DOCS}/home/defend_your_workstation`}
                    text="Defend Your Workstation"
                    isExternal={true}
                  />
                  <NavItem
                    link={`${ROUTES.EXTERNAL.DOCS}/integrations/integrations_overview`}
                    text="Explore Integrations"
                    isExternal={true}
                  />
                  <NavItem
                    link="https://api.phylum.io/api/v0/swagger/index.html"
                    text="API Reference"
                    isExternal={true}
                  />
                </NavWrap>
              </Flex>
            )}
          >
            <Icon
              path={mdiBookOpenPageVariantOutline}
              color="text.primary"
              size={1}
              sx={{
                height: ['32px', '32px', '32px', '32px', '40px'],
                cursor: 'pointer',
                mr: '1.5em',
              }}
            />
          </Tooltip>
          <Tooltip
            hoverable={false}
            side="bottomright"
            renderTooltip={() => (
              <Flex flexDirection="column" minWidth="23em" p="1.5em" pb="0.125em">
                {authState.state === 'isAuthenticated' && (
                  <UserLabel {...user} />
                )}
                <NavWrap>
                  {authState.state === 'isAuthenticated' && (
                    <>
                      <NavItem
                        link={env.OIDC_ACCOUNT_URL}
                        text="Account Manager"
                        isExternal={true}
                      />
                      <NavItem
                        link={ROUTES.LOCKSMITH_TOKENS.path}
                        text="API Access Token"
                      />
                      {githubInstallations?.length > 0 && (
                        <NavItem
                          link={ROUTES.GITHUB_APP_SETTINGS.path}
                          text="GitHub App Settings"
                        />
                      )}
                    </>
                  )}
                  <NavItem link={ROUTES.QUICKSTART.path} text="Quickstart" />
                  <Divider />
                  <Flex
                    mt='1em'
                    mb='0.5em'
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {authState.state === 'isAuthenticated' ? (
                      <NavLink
                        onClick={() => {
                          authState.logout();
                        }}
                      >
                        <NavText className="text" data-test="sign-out">
                          Sign Out
                        </NavText>
                      </NavLink>
                    ) : (
                      <NavLink
                        onClick={() => {
                          if (loginLink) window.location.href = loginLink;
                        }}
                      >
                        <NavText className="text">Sign In</NavText>
                      </NavLink>
                    )}
                    <NavLink
                      href={ROUTES.EXTERNAL.CONTACT}
                      justifyContent="flex-end"
                    >
                      <NavText className="text">
                        Contact Us{' '}
                        <Image
                          src={LinkIcon}
                          alt="link out icon"
                          sx={{
                            height: '13px',
                            marginLeft: '8px',
                            verticalAlign: 'middle',
                          }}
                        />
                      </NavText>
                    </NavLink>
                  </Flex>
                  <ToggleExperiment {...user} />
                </NavWrap>
              </Flex>
            )}
          >
            <Image
              mr="1em"
              src={UserSVG}
              data-test="open-user-nav"
              alt="user icon"
              sx={{
                height: ['32px', '32px', '32px', '32px', '40px'],
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </Flex>
        <SearchBar />
      </StyledHeader>
      {!loginLink && <Nav />}
    </>
  );
};

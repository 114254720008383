import React from 'react';
import styled from '@emotion/styled/macro';
import { Box, BoxProps } from 'components/primitive/Box/Box';
import { useRouter } from 'hooks/useRouter';

export type TooltipPosition =
  | 'top'
  | 'right'
  | 'bottom'
  | 'bottomright'
  | 'left'
  | 'righttop'
  | 'righttopin'
  | 'topright'
  | 'topleft'
  | 'left'
  | 'lefttop';

export interface TooltipProps extends BoxProps {
  side?: TooltipPosition;
  containerProps?: BoxProps;
  renderTooltip: () => React.ReactNode;
  hoverable?: boolean;
}

const TooltipWrapper = styled(Box)`
  position: relative;
  &.hoverable,
  &:not(.expanded) {
    & > #tooltip-container {
      pointer-events: none;
      touch-action: none;
    }
  }

  & > #tooltip-container {
    opacity: 0;
    position: absolute;
    padding: 0;
    transition: 250ms linear;

    &.top {
      top: -20px;
      left: 50%;
      transform: translate3d(-50%, -90%, 0);
    }
    &.topright {
      top: -10px;
      left: 75%;
      transform: translate3d(-100%, -90%, 0);
    }
    &.topleft {
      top: -190px;
      left: 0;
      transform: translate3d(0, 0, 0);
    }
    &.right {
      right: -20px;
      top: 50%;
      transform: translate3d(90%, -50%, 0);
    }
    &.righttop {
      top: -10px;
      right: -20px;
      transform: translate3d(90%, 0, 0);
    }
    &.righttopin {
      top: -20px;
      right: 50%;
      transform: translate3d(90%, 0, 0);
    }
    &.bottom {
      bottom: -20px;
      left: 50%;
      transform: translate3d(-50%, 90%, 0);
    }
    &.bottomright {
      bottom: -20px;
      left: 100%;
      transform: translate3d(-100%, 90%, 0);
    }
    &.left {
      left: -20px;
      top: 50%;
      transform: translate3d(-90%, -50%, 0);
    }
    &.lefttop {
      top: 0;
      left: -20px;
      transform: translate3d(-90%, 0, 0);
    }

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      height: 15px;
      width: 15px;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      background: var(--grey-2);
    }

    &.default {
      &.top {
        &::before {
          left: calc(50% - 13px);
          bottom: -5px;
        }
      }
      &.topright {
        &::before {
          right: 27px;
          bottom: -5px;
        }
      }
      &.bottom {
        &::before {
          left: calc(50% - 13px);
          top: -5px;
        }
      }
      &.bottomright {
        &::before {
          right: 27px;
          top: -5px;
        }
      }
      &.right {
        &::before {
          top: calc(50% - 8px);
          left: -5px;
        }
      }
      &.righttop {
        &::before {
          top: 30px;
          left: -5px;
        }
      }
      &.righttopin {
        &::before {
          top: 30px;
          left: -5px;
        }
      }
      &.left {
        &::before {
          top: calc(50% - 8px);
          right: -5px;
        }
      }
      &.lefttop {
        &::before {
          top: 13px;
          right: -5px;
        }
      }
    }

    &.large {
      &.top {
        &::before {
          left: calc(50% - 13px);
          bottom: -5px;
        }
      }
      &.topright {
        &::before {
          right: 27px;
          bottom: -5px;
        }
      }
      &.topleft {
        &::before {
          right: 27px;
          bottom: -5px;
        }
      }
      &.bottom {
        &::before {
          left: calc(50% - 13px);
          top: -5px;
        }
      }
      &.bottomright {
        &::before {
          right: 27px;
          top: -5px;
        }
      }
      &.right {
        &::before {
          top: calc(50% - 13px);
          left: -5px;
        }
      }
      &.righttop {
        &::before {
          top: 27px;
          left: -5px;
        }
      }
      &.righttopin {
        &::before {
          top: 27px;
          right: -5px;
        }
      }
      &.left {
        &::before {
          top: calc(50% - 13px);
          right: -5px;
        }
      }
      &.lefttop {
        &::before {
          top: 27px;
          right: -5px;
        }
      }
    }
  }

  &:not(.hoverable).expanded,
  &.hoverable:hover {
    & > #tooltip-container {
      opacity: 1;
      &.top {
        transform: translate3d(-50%, -100%, 0);
      }
      &.topright {
        transform: translate3d(-100%, -100%, 0);
      }
      &.topleft {
        transform: translate3d(0, 0, 0);
      }
      &.right {
        transform: translate3d(100%, -50%, 0);
      }
      &.righttop {
        transform: translate3d(100%, 0, 0);
      }
      &.righttopin {
        transform: translate3d(100%, 0, 0);
      }
      &.bottom {
        transform: translate3d(-50%, 100%, 0);
      }
      &.bottomright {
        transform: translate3d(-100%, 100%, 0);
      }
      &.left {
        transform: translate3d(-100%, -50%, 0);
      }
      &.lefttop {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }
`;

export function Tooltip({
  variant = 'default',
  side = 'top',
  containerProps = {},
  renderTooltip,
  children,
  hoverable = true,
  ...props
}: TooltipProps): JSX.Element {
  const { pathname } = useRouter();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = React.useState(false);
  const hoverClass = hoverable ? 'hoverable' : '';
  const expandedClass = expanded ? 'expanded' : '';

  const expand = () => setExpanded((prevExpanded) => !prevExpanded);

  const minimizeListener = (e: Event) => {
    if (
      e?.target instanceof Node &&
      !containerRef.current?.contains(e.target)
    ) {
      setExpanded(false);
    }
  };

  React.useEffect(() => {
    if (expanded) {
      window.addEventListener('click', minimizeListener);
    } else {
      window.removeEventListener('click', minimizeListener);
    }
    return () => window.removeEventListener('click', minimizeListener);
  }, [expanded]);

  React.useEffect(() => setExpanded(false), [pathname]);

  return (
    <span ref={containerRef}>
      <TooltipWrapper {...props} className={`${hoverClass} ${expandedClass}`}>
        <Box
          variant={`tooltips.${variant}`}
          id="tooltip-container"
          className={`${variant} ${side}`}
          {...containerProps}
          style={{
            zIndex: 1000,
          }}
        >
          <>{renderTooltip()}</>
        </Box>
        <Box onClick={expand}>{children}</Box>
      </TooltipWrapper>
    </span>
  );
}
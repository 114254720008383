import { generateCodeVerifierAndChallengeCode } from 'utils/pkce';
import {
  action,
  autorun,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { Tokens } from 'utils/token';
import { base64UrlSafeDecode } from 'utils/base64';
import {
  buildAuthorizationUrl,
  buildTokenUrlPostDataGrantTypeAuthorizationCode,
  buildTokenUrlPostDataGrantTypeRefreshToken,
  buildUserRegistrationUrl,
  buildLogoutUrl,
  fetchOidcIdentityProviderSettings,
} from 'utils/oauth';
import { generateRandomString } from 'utils/random';
import axios from 'axios';

const ONE_MINUTE_MILLIS = 60 * 1000;

function extractUserFromToken(idToken: string): IUser {
  const decoder = new TextDecoder();
  const [, payload] = idToken.split('.');
  const userData = JSON.parse(decoder.decode(base64UrlSafeDecode(payload)));
  return userData as IUser;
}
/**
 * Produce a AuthContext Store
 */
export class AuthContextStore {
  private state: AuthenticationStage = { stage: 'initial' };
  private setTimeoutHandle: number | null = null;

  /**
   * Build an auth context store instance.
   *
   * @param oidcDiscoveryUrl The url to fetch IDP settings from
   * @param redirectUrl The URL to tell the auth server to redirect back to
   * @param oidcClientId The client id to send to the auth server
   * @returns A AuthContextStore instance
   */
  public static async buildForOidcDiscoveryUrl(
    oidcDiscoveryUrl: URL,
    redirectUrl: URL,
    oidcClientId: string
  ): Promise<AuthContextStore> {
    const oidcIdpSettings = await fetchOidcIdentityProviderSettings(
      oidcDiscoveryUrl
    );
    return new AuthContextStore(
      `auth_context_store_${oidcDiscoveryUrl}`,
      oidcClientId,
      oidcIdpSettings,
      redirectUrl
    );
  }

  /**
   * Build an auth context store instance.
   *
   * @param oidcDiscoveryUrl The url to fetch IDP settings from
   * @param redirectUrl The URL to tell the auth server to redirect back to
   * @param oidcClientId The client id to send to the auth server
   * @returns A AuthContextStore instance
   */
  public static buildForTest(): AuthContextStore {
    const SETTINGS: IdentityProviderSettings = {
      issuer: ' ',
      authorization_endpoint: ' ',
      token_endpoint: ' ',
      introspection_endpoint: ' ',
      userinfo_endpoint: ' ',
      end_session_endpoint: ' ',
      jwks_uri: ' ',
      registration_endpoint: ' ',
    };
    const FAKE_URL = new URL('http://localhost:3000');
    return new AuthContextStore(
      'auth_context_store_TEST',
      'TEST',
      SETTINGS,
      FAKE_URL
    );
  }

  /**
   * Please make sure only instance of this class is created for a given
   * discovery url at a time. Otherwise conflicts may occur due to persisting
   * and restoring state.
   *
   * @param oidcDiscoveryUrl The URL to query for authorization server information.
   */
  private constructor(
    private readonly authFlowStateStorageKey: string,
    private readonly oidcClientId: string,
    private readonly oidcIdpSettings: IdentityProviderSettings,
    private readonly redirectUrl: URL
  ) {
    makeAutoObservable<
      AuthContextStore,
      | 'state'
      | 'restoreState'
      | 'logout'
      | 'processCallback'
      | 'tryStartRefresh'
      | 'tryObtainTokens'
      | 'doRefresh'
      | 'setErrorState'
      | 'reset'
    >(this, {
      state: observable,
      authState: computed.struct,
      restoreState: action,
      processCallback: action,
      logout: action,
      tryObtainTokens: action,
      tryStartRefresh: action,
      doRefresh: action,
      setErrorState: action,
      reset: action,
      tokens: computed.struct,
    });
    this.restoreState().then(() =>
      autorun(() => {
        if (this.state.stage === 'tryStartRefresh') {
          this.tryStartRefresh();
        }
        this.saveState();
      })
    );
  }

  /**
   * Restore our state from localstorage on reload
   */
  async restoreState(): Promise<void> {
    let state: AuthenticationStage;
    try {
      const savedStateJson =
        localStorage.getItem(this.authFlowStateStorageKey) || 'null';
      let savedState = JSON.parse(
        savedStateJson
      ) as unknown as AuthenticationStage | null;
      // These are the only two valid states that should be persisted
      if (
        savedState &&
        (savedState.stage === 'refreshing' ||
          savedState.stage === 'awaitingCallback')
      ) {
        if (savedState.stage == 'refreshing') {
          // State was saved during refreshing, so we need to enter try refresh state
          savedState = {
            stage: 'tryStartRefresh',
            tokens: savedState.tokens,
          };
        }
        // TS seems to lose track of the fact state can't be null here, so we just cast to enforce it.
        state = savedState as AuthenticationStage;
      } else {
        // clear the saved state, might be bad
        localStorage.removeItem(this.authFlowStateStorageKey);
        const codes = await generateCodeVerifierAndChallengeCode(64);
        // TODO In the future use the state key to store pre-auth state, such as URL that triggered
        // request ( browsed to a protected resource )
        const requestState = generateRandomString(32);
        const authorizationUrl = buildAuthorizationUrl(
          new URL(
            (
              this.oidcIdpSettings as IdentityProviderSettings
            ).authorization_endpoint
          ),
          this.redirectUrl,
          this.oidcClientId,
          codes.challengeCode,
          requestState
        );
        const registrationUrl = buildUserRegistrationUrl(
          new URL(
            (
              this.oidcIdpSettings as IdentityProviderSettings
            ).authorization_endpoint
          ),
          this.redirectUrl,
          this.oidcClientId,
          codes.challengeCode,
          requestState
        );
        state = {
          stage: 'awaitingCallback',
          state: requestState,
          codes,
          registrationUrl: registrationUrl.toString(),
          authorizationUrl: authorizationUrl.toString(),
        };
      }
      runInAction(() => {
        this.state = state;
      });
    } catch (error) {
      console.info('Encountered error in restoreState()');
      this.reset();
    }
  }

  /**
   * Only returns tokens if authenticated
   */
  public get tokens(): Tokens | null {
    if (this.state.stage === 'refreshing') {
      return this.state.tokens;
    }
    return null;
  }

  /**
   * Try to obtain tokens using the auth code
   */
  private async tryObtainTokens() {
    if (this.state.stage != 'tryObtainTokens') {
      console.warn("Tried to call 'tryObtainTokens' in wrong state");
      return;
    }

    try {
      const data = buildTokenUrlPostDataGrantTypeAuthorizationCode(
        this.redirectUrl,
        this.oidcClientId,
        this.state.authorizationCode,
        this.state.codeVerifier
      );

      const tokenUrl = this.oidcIdpSettings.token_endpoint;

      const response = await axios.post(tokenUrl.toString(), data, {
        headers: {
          Accept: 'application/json',
        },
      });

      
      if (response.status == 200) {
        this.tryToScheduleNextRefresh(response.data as Tokens);
      } else {
        throw new Error(
          `Could not obtain tokens from '${tokenUrl}' using authorization code, check your settings`
        );
      }
    } catch (error) {
      console.info('Encountered error in tryObtainTokens()');
      this.reset();
    }
  }

  /**
   * Try to start refresh of tokens using tokens we already have
   */
  private tryStartRefresh(): void {
    if (this.state.stage != 'tryStartRefresh') {
      console.warn("Tried to call 'tryStartRefresh' in wrong state");
      return;
    }
    try {
      this.state = {
        ...this.state,
        stage: 'refreshing',
      };
      this.doRefresh();
    } catch (error) {
      console.info('Encountered error in tryStartRefresh()');
      this.reset();
    }
  }

  private async doRefresh() {
    try {
      if (this.state.stage == 'refreshing') {
        const tokenRefreshUrl = this.oidcIdpSettings.token_endpoint;
        const data = buildTokenUrlPostDataGrantTypeRefreshToken(
          this.oidcClientId,
          this.state.tokens.refresh_token
        );
        const response = await axios.post(tokenRefreshUrl.toString(), data, {
          headers: {
            Accept: 'application/json',
          },
        });
        if (response.status == 200) {
          this.tryToScheduleNextRefresh(response.data as Tokens);
        } else {
          throw new Error(
            `Could not obtain tokens from '${tokenRefreshUrl}' using refresh token, check your settings`
          );
        }
      } else {
        console.warn("Tried to call 'doRefresh' in wrong state");
        return;
      }
    } catch (error) {
      console.info('Encountered error in doRefresh()');
      this.reset();
    }
  }

  /**
   * Set the auth context store state back to initial
   */
  public acknowledgeAuthenticationError(): void {
    this.reset();
  }

  public processCallback(callbackUrl: string): void {
    if (this.state.stage != 'awaitingCallback') {
      console.warn("Tried to call 'processCallback' in wrong state");
      return;
    }
    try {
      const hash = new URL(callbackUrl).hash.substr(1);
      const urlParams = new URLSearchParams(hash);
      const authorizationCode = urlParams.get('code');

      if (!authorizationCode || authorizationCode.length == 0) {
        throw new Error('Authorization code had zero length!');
      }
      const state = urlParams.get('state');
      if (!state || state.length == 0) {
        throw new Error('Auth server did return submitted state nonce, CSRF?');
      }
      this.state = {
        stage: 'tryObtainTokens',
        authorizationCode: authorizationCode,
        codeVerifier: this.state.codes.codeVerifier,
      };
      this.tryObtainTokens();
    } catch (error) {
      console.info(error);
      this.reset();
    }
  }

  private tryToScheduleNextRefresh(tokens: Tokens) {
    // Needs to be done in action...
    runInAction(() => {
      this.state = {
        stage: 'refreshing',
        tokens,
      };
    });

    // Clear any outstanding timeout
    this.clearRefreshTimeout();
    // Schedule refresh one minute before access token expires.
    let expiresInMs = tokens.expires_in * 1000 - ONE_MINUTE_MILLIS;
    if (expiresInMs < ONE_MINUTE_MILLIS) {
      expiresInMs = ONE_MINUTE_MILLIS;
    }
    this.setTimeoutHandle = window.setTimeout(
      () => this.doRefresh(),
      expiresInMs
    );
  }

  private clearRefreshTimeout() {
    if (this.setTimeoutHandle) clearTimeout(this.setTimeoutHandle);
    this.setTimeoutHandle = null;
  }

  private reset() {
    this.clearRefreshTimeout();
    localStorage.removeItem(this.authFlowStateStorageKey);
    this.state = { stage: 'initial' };
    this.restoreState();
  }

  private saveState(): void {
    const stage = this.state.stage;
    if (stage === 'awaitingCallback' || stage === 'refreshing') {
      // We are either awaiting callback so we need to store the verfier for
      // getting tokens, or we are refreshing tokens already, so we need to store the tokens
      localStorage.setItem(
        this.authFlowStateStorageKey,
        JSON.stringify(this.state)
      );
    }
  }

  public get authState(): AuthState {
    try {
      switch (this.state.stage) {
        case 'tryObtainTokens':
        case 'tryStartRefresh': {
          return { state: 'authenticating' };
        }
        case 'initial': {
          return { state: 'unknown' };
        }
        case 'awaitingCallback': {
          return {
            state: 'needAuthorization',
            registrationUrl: this.state.registrationUrl,
            authorizationUrl: this.state.authorizationUrl,
            acknowledge: (callbackUrl) => {
              this.processCallback(callbackUrl);
            },
          };
        }

        case 'refreshing': {
          const logoutUrl = buildLogoutUrl(
            new URL(
              (
                this.oidcIdpSettings as IdentityProviderSettings
              ).end_session_endpoint
            ),
            this.redirectUrl,
            this.oidcClientId,
            this.state.tokens.id_token
          );
          return {
            state: 'isAuthenticated',
            user: extractUserFromToken(this.state.tokens.access_token),
            access_token: this.state.tokens.access_token,
            logout: async () => {
              this.clearRefreshTimeout();
              localStorage.removeItem(this.authFlowStateStorageKey);
              window.location.href = logoutUrl.toString();
            },
          };
        }
      }
    } catch (error) {
      console.info('Encountered error in authState() getter');
      this.reset();
      return this.authState;
    }
  }
}

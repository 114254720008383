import React from 'react';

export const SearchContext =
  React.createContext<SearchResultContextType | null>(null);

export const useSearch = (): SearchResultContextType => {
  const context = React.useContext(SearchContext);
  if (!context) {
    throw new Error(`ugh`);
  }
  return context;
};

export const SearchProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [projects, updateProjects] = React.useState<SearchContextEntity>();
  const [projectPackages, updateProjectPackages] =
    React.useState<SearchContextEntity>();
  const [phylumPackages, updatePhylumPackages] =
    React.useState<SearchContextEntity>();

  return (
    <SearchContext.Provider
      value={{
        ProjectPackages: {
          value: projectPackages,
          update: updateProjectPackages,
        },
        PhylumPackages: {
          value: phylumPackages,
          update: updatePhylumPackages,
        },
        Projects: {
          value: projects,
          update: updateProjects,
        },
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Contexts } from 'contexts';
import { ThemeProvider } from 'theme-ui';
import { ROUTES } from 'routes';
import { theme } from '../../assets/theme';
import { lazyImport } from 'utils/lazyImport';
import { AppRoute } from 'components/layout/AppRoute';
import { PageContent } from './components/PageContent';
import { AppContainer } from './components/AppContainer';
import { Login } from 'views/Login';

const { AccessAdmin } = lazyImport(
  () => import('views/Locksmith'),
  'AccessAdmin'
);
const { Dashboard } = lazyImport(() => import('views/Dashboard'), 'Dashboard');
const { EventLogs } = lazyImport(() => import('views/EventLogs'), 'EventLogs');
const { Package } = lazyImport(() => import('views/Packages'), 'Package');
const { Projects } = lazyImport(() => import('views/Projects'), 'Projects')
const { SearchResults } = lazyImport(
  () => import('views/SearchResults'),
  'SearchResults'
);
const { GitHubApp } = lazyImport(() => import('views/GitHubApp'), 'GitHubApp');
const { Groups } = lazyImport(() => import('views/Groups'), 'Groups');
const { Upgrade } = lazyImport(() => import('views/Upgrade'), 'Upgrade');
const { Quickstart } = lazyImport(
  () => import('views/Quickstart'),
  'Quickstart'
);
const { FourOhFour } = lazyImport(
  () => import('components/screens/FourOhFour'),
  'FourOhFour'
);

const Phylum = () => {
  return (
    <PageContent>
      <Switch>
        <AppRoute
          exact
          path={[
            ROUTES.INDEX.path,
            ROUTES.DASHBOARD.path,
            ROUTES.REGISTER.path,
          ]}
          component={Dashboard}
        />
        <AppRoute path={ROUTES.PROJECTS.path} component={Projects} />
        <AppRoute
          path={[ROUTES.PACKAGE_DETAILS.path, ROUTES.PACKAGE_NO_VERSION.path]}
          component={Package}
        />
        <AppRoute path={ROUTES.RESULTS.path} component={SearchResults} />
        <AppRoute path={ROUTES.EVENT_LOGS.path} component={EventLogs} />
        <AppRoute
          path={[
            ROUTES.GITHUB_APP_SETTINGS.path,
            ROUTES.GITHUB_APP_INSTALLATION.path,
          ]}
          component={GitHubApp}
        />
        <AppRoute path={ROUTES.GROUPS.path} component={Groups} />
        <AppRoute path={ROUTES.LOCKSMITH.path} component={AccessAdmin} />
        <AppRoute
          path={[ROUTES.UPGRADE_GROUPS.path, ROUTES.UPGRADE_EVENTS.path]}
          component={Upgrade}
        />
        <AppRoute
          path={[ROUTES.UPGRADE.path, ROUTES.UPGRADE_NO_AUTH.path]}
          component={Upgrade}
        />
        <AppRoute path={ROUTES.QUICKSTART.path} component={Quickstart} />
        <AppRoute component={FourOhFour} />
      </Switch>
    </PageContent>
  );
};

export const Router = () => (
  <AppContainer>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Contexts>
          <Switch>
            <AppRoute path={ROUTES.AUTH.path} component={Login} />
            <AppRoute path={ROUTES.INDEX.path} component={Phylum} />
          </Switch>
        </Contexts>
      </BrowserRouter>
    </ThemeProvider>
  </AppContainer>
);

export default Router;

// Base64 related utilties

/**
 * Base64 encode strings or bytes.
 *
 * String will be transformed into utf-8 first.
 *
 * @param data
 */
export function base64Encode(data: string | Uint8Array): string {
  let uint8Data;
  if (typeof data == 'string') {
    const encoder = new TextEncoder();
    uint8Data = encoder.encode(data);
  } else {
    uint8Data = data;
  }
  const binaryString = String.fromCharCode(...Array.from(uint8Data));
  return btoa(binaryString);
}

/**
 * Decode a base64 encoded value into a binary array
 *
 * This makes no assumpton about byte result of text encoding, but if the string
 * was originally encoded with {@link base64Encode} then the output should be
 * utf-8 and can be decoded with {@link TextDecoder}
 *
 * @param base64String
 */
export function base64Decode(base64String: string): Uint8Array {
  const binaryString = atob(base64String);
  const binaryData = new Uint8Array(binaryString.length);
  binaryString.split('').forEach((v, i) => {
    binaryData[i] = binaryString.charCodeAt(i);
  });
  return binaryData;
}

/**
 * Base64 encode strings or bytes in a url-safe manner.
 *
 * String will be transformed into utf-8 first.
 *
 * @param data
 * @returns
 */
export function base64UrlSafeEncode(data: string | Uint8Array): string {
  return base64ToBase64Url(base64Encode(data));
}

/**
 * Decode a base64 url safe encoded value into a binary array
 *
 * This makes no assumpton about byte result of text encoding, but if the string
 * was originally encoded with {@link base64Encode} then the output should be
 * utf-8 and can be decoded with {@link TextDecoder}
 *
 * @param base64UrlString
 * @returns
 */
export function base64UrlSafeDecode(base64UrlString: string): Uint8Array {
  return base64Decode(base64UrltoBase64(base64UrlString));
}

/**
 * Convert a standard base64 encoded value to the url safe base64 format
 *
 * * trailing '=' is removed.
 * * '+' => '-'
 * * '/' => '_'
 */
function base64ToBase64Url(base64: string): string {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

/**
 * Convert a Base64Url value to a plain base64 value
 *
 * * Padded to multiples of 4 bytes with '='
 * * '-' => '+'
 * * '_' => '/'
 * @param base64Url
 */
function base64UrltoBase64(base64Url: string): string {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const numPadChars = (4 - (base64Url.length % 4)) % 4;
  return base64 + '='.repeat(numPadChars);
}

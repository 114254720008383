import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  matchPath,
  generatePath,
} from 'react-router-dom';
import { Authorize } from './Authorize';
import { Callback } from './Callback';
import { useAuth } from 'hooks/useAuth';
import { ROUTES } from 'routes';
import { useRouter } from 'hooks/useRouter';
import { UpgradeNoAuth } from 'views/Upgrade';

export const Router = () => {
  const { push } = useHistory();
  const authState = useAuth();
  const { pathname } = useRouter();

  React.useEffect(() => {
    if (authState.state === 'isAuthenticated') {
      // retrieve the desired page path for previously unauthenticated user, clear for the future
      const INIT_PATH = localStorage.getItem('init-path') || ROUTES.INDEX.path;
      localStorage.removeItem('init-path');
      push(INIT_PATH);
    } else if (authState.state === 'needAuthorization') {
      if (matchPath(pathname, ROUTES.UPGRADE_NO_AUTH)) {
        push(ROUTES.UPGRADE_NO_AUTH.path);
      } else if (matchPath(pathname, ROUTES.REGISTER)) {
        push(generatePath(ROUTES.LOGIN.path, {}));
      } else if (!matchPath(pathname, ROUTES.LOGIN)) {
        if (!matchPath(pathname, ROUTES.LOGIN_CALLBACK)) {
          // remember the desired page path for an unauthenticated user
          localStorage.setItem('init-path', pathname);
        }
        push(generatePath(ROUTES.LOGIN.path, {}));
      }
    }
  }, [authState]);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.LOGIN.path}
        render={(props) => <Authorize {...props} authState={authState} />}
      />
      <Route
        path={ROUTES.LOGIN_CALLBACK.path}
        render={(props) => <Callback {...props} authState={authState} />}
      />
      <Route
        path={ROUTES.UPGRADE_NO_AUTH.path}
        render={() => <UpgradeNoAuth />}
      />
    </Switch>
  );
};

export default Router;

interface SpaceProperties {
  pageContent: {
    authenticated: string;
  };
  optionsColumn: {
    small: number;
    medium: number;
    large: number;
  };
}

export type IspaceScaleArray = Array<number> & SpaceProperties;

const spaceScale = [0, 4, 8, 12, 14, 16, 20, 24, 28, 32, 34, 36, 40, 44, 48];
const spaceScaleObject = {
  pageContent: {
    authenticated: 'calc(100vh - 124px)',
  },
  optionsColumn: {
    small: 208,
    medium: 238,
    large: 311,
  },
};

export const space: IspaceScaleArray = Object.assign(
  {},
  spaceScale,
  spaceScaleObject
);

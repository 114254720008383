import { useAuth } from 'hooks/useAuth';
export const currentUser = (): User => {
  const authState = useAuth();
  const isAuthenticated = authState.state == 'isAuthenticated';
  const tier = !isAuthenticated
    ? 'unknown'
    : authState.user.realm_access.roles.includes('pro-account')
    ? 'pro'
    : 'community';

  return {
    tier: tier,
    isPro: tier == 'pro',
    isWIP: isAuthenticated && authState.user.realm_access.roles.includes('ui-wip'),
    // 🚧 storing the WIP emoji here
    accessToken: isAuthenticated ? authState.access_token : null,
    email: isAuthenticated ? authState.user.email : '',
    name: isAuthenticated ? authState.user.name : '',
  };
};

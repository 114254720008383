import React from 'react';
import { Text as RebassText, TextProps as RebassTextProps } from 'rebass';

import { SxStyleProp } from 'theme-ui';

export type TextProps = Omit<RebassTextProps, BrokenRebassProps>;

const variantTagName = (
  // eslint-disable-next-line
  variant: any,
  // eslint-disable-next-line
  as: any
  // eslint-disable-next-line
): any => {
  if (/h\d/i.test(variant)) {
    return variant.toLowerCase();
  }

  switch (variant) {
    case 'mono':
      return 'code';
    case undefined:
      return 'span';
    default:
      return as;
  }
};

export const Text = ({
  as = 'span',
  variant = 'body1',
  ...props
}: TextProps) => (
  <RebassText as={variantTagName(variant, as)} {...{ variant, ...props }} />
);

export const ResponsiveBodyText = ({
  children,
  sx,
  variant = 'body1',
  test,
}: {
  children: React.ReactNode;
  sx?: SxStyleProp;
  test?: string;
  variant?: string;
}) => {
  return (
    <Text
      variant={variant}
      data-test={test}
      fontSize={['12px', '12px', '12px', '12px', '14px']}
      sx={{ ...sx }}
    >
      <>{children}</>
    </Text>
  );
};

import React from 'react';
import MdiIcon from '@mdi/react';
import { IconProps as MdiIconProps } from '@mdi/react/dist/IconProps';
import { Box, BoxProps } from 'components/primitive/Box/Box';
import { useThemeUI } from 'theme-ui';
import _get from 'lodash/get';
import { PhylumUiTheme } from 'assets/theme';

type UsedBoxProps = 'sx' | 'className' | 'as' | 'variant' | 'onClick';

export interface IconProps extends MdiIconProps, Pick<BoxProps, UsedBoxProps> {}

export const Icon = ({
  className,
  color: _color = 'text.primary',
  sx = {},
  as = 'span',
  variant,
  size = 1,
  ...props
}: IconProps) => {
  const { theme } = useThemeUI() as unknown as { theme: PhylumUiTheme };
  const color: string = _get(theme.colors, _color || '', _color) as string;
  return (
    <Box {...{ sx, className, as, variant }}>
      <MdiIcon {...{ size, color }} {...props} />
    </Box>
  );
};

import React from 'react';
import styled from '@emotion/styled/macro';
import { Box, BoxProps } from 'components/primitive/Box/Box';
import { PhylumUiTheme } from 'assets/theme';

import { Header } from 'components/layout/Header';

export type PageContentProps = Omit<BoxProps, BrokenRebassProps>;

interface WrapperProps extends PageContentProps {
  theme: PhylumUiTheme;
}

const StyledPageContent = styled(Box)<WrapperProps>`
  position: absolute;
  top: 0;
  z-index: 40;
  width: 100vw;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: fit-content;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0 24px 40px 24px;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: var(--grey-1);
`;

export const PageContent = ({ children, ...props }: PageContentProps) => {
  return (
    <>
      <Header />
      <div id="banner-container" />
      <div id="modal-container" />
      <StyledPageContent as="main" id="page-content" {...props}>
        <Box
          as="section"
          width={['46em', '46em', '46em', '56em', '75.5em', '79.5em']}
          sx={{
            mx: 'auto',
            maxWidth: '100%',
            minWidth: '46em',
            minHeight: '100vh',
            backgroundColor: 'var(--grey-1)',
          }}
        >
          {children}
        </Box>
      </StyledPageContent>
    </>
  );
};
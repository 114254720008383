import React from 'react';
import { AuthContext } from 'contexts/AuthContext';

// Hook for child components to get the auth object and re-render when it changes.
export function useAuth(): AuthState {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

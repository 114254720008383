import { typography } from '../typography';

const commonDefault = {
  ...typography.text.body2,
  color: 'text.primary',
  bg: 'background.main',
  boxShadow: '0px 10px 50px rgba(14, 10, 31, 0.3)',
  borderRadius: '3px',
  py: '10px',
  px: '20px',
};

export const tooltips = {
  default: {
    ...commonDefault,
  },
  large: {
    ...commonDefault,
    minHeight: '100px',
    minWidth: '278px',
  },
  hidden: {
    display: 'none',
  },
};

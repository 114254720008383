import React from 'react';

interface ContextData {
  flag: FeatureFlag;
  updateFlag: (newFlag: FeatureFlag) => void;
}

export const ExperimentalFeatureContext = React.createContext<
  ContextData | undefined
>(undefined);

export function ExperimentalFlagProvider({
  children,
}: {
  children: React.ReactNode;
}) {

  const lastRecorderFlag = localStorage.getItem(
    'experimental'
  ) as unknown as FeatureFlag;
  const [flag, setFlag] = React.useState<FeatureFlag>(lastRecorderFlag || 'off');
  const updateFlag = (newFlag: FeatureFlag) => {
    setFlag(newFlag);
  };

  const flagContextValue: ContextData = {
    flag,
    updateFlag,
  };

  return (
    <ExperimentalFeatureContext.Provider value={flagContextValue}>
      {children}
    </ExperimentalFeatureContext.Provider>
  );
}

import React from 'react';
import styled from '@emotion/styled/macro';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ReLink, LinkProps as ReLinkProps } from 'rebass';

export interface LinkProps extends Omit<ReLinkProps, BrokenRebassProps> {
  component?: React.ComponentType<unknown>;
  to?: unknown;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
  noreferrer?: boolean;
  noopener?: boolean;
  _blank?: boolean;
  variant?: string;
  underline?: boolean;
  hoverline?: boolean;
}

// eslint-disable-next-line
const StyledLink = styled(ReLink)((props: any) => ({
  cursor: 'pointer',
  fontFamily: props.theme.fonts.default,
  fontWeight: props?.style?.fontWeight ? props.style.fontWeight : 400,
  color: props?.style?.color ? props.style.color : props.theme.colors.text.link,
  sx: props.sx,
}));

export const Link = ({
  rel = '',
  variant = 'body1',
  noopener,
  noreferrer,
  _blank,
  underline,
  hoverline,
  sx,
  ...props
}: LinkProps) => {
  const _noopener = noopener ? 'noopener' : '';
  const _noreferrer = noreferrer ? 'noreferrer' : '';
  const LinkElement = props.to ? RouterLink : 'a';

  return (
    <StyledLink
      as={LinkElement}
      variant={variant}
      rel={`${rel} ${_noopener} ${_noreferrer}`}
      target={_blank ? '_blank' : undefined}
      sx={{
        textDecoration: underline ? 'underline' : 'none',
        ':hover': { textDecoration: hoverline ? 'underline' : 'none' },
        ':focus': { textDecoration: hoverline ? 'underline' : 'none' },
        ...sx,
      }}
      tabIndex={0}
      {...props}
    />
  );
};

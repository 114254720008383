/* eslint-disable @typescript-eslint/no-unused-vars */

type ReturnType<E extends boolean, V> = E extends true ? V : V | undefined;

function parseEnvString<E extends boolean>(
  envVarName: string,
  required?: E
): ReturnType<E, string>;
function parseEnvString(
  envVarName: string,
  required = false
): ReturnType<typeof required, string> {
  const envVar = process.env[`REACT_APP_${envVarName}`];
  required = required ?? false;

  if (required && (typeof envVar === 'undefined' || envVar == null)) {
    console.error(
      `⛔️ WARNING ⛔️\n===========\n${envVarName} is undefined or value is null`
    );
    // 💣💣 Blow up the app 💣💣
    throw new Error(
      `${envVarName} is required, but value is undefined or null!`
    );
  }
  return envVar;
}

function parseEnvUrl<E extends boolean>(
  envVarName: string,
  required?: E
): ReturnType<E, URL>;
function parseEnvUrl(
  envVarName: string,
  required = false
): ReturnType<typeof required, URL> {
  const envValue = parseEnvString(envVarName, required);
  if (envValue) {
    return new URL(envValue);
  } else {
    return undefined;
  }
}

// TODO Should have a config object built from multiple sources.
export const env = {
  API_URL: parseEnvUrl('API_URL', true),
  OIDC_DISCOVERY_URL: parseEnvUrl('OIDC_DISCOVERY_URL', true),
  OIDC_CLIENT_ID: parseEnvString('OIDC_CLIENT_ID', true),
  OIDC_ACCOUNT_URL: parseEnvString('OIDC_ACCOUNT_URL', true),
};

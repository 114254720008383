const common = {
  borderRadius: '3px',
  py: '1.125em',
  px: '1.5em',
  width: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
};

const packageMsg = {
  borderColor: 'var(--grey-3)',
  height: '60px',
  color: 'text.main',
};
export const banners = {
  warning: {
    ...common,
    bg: 'var(--yellow-bg)',
    borderColor: 'var(--yellow-border)',
    backdropFilter: 'blur(5px)',
  },
  error: {
    ...common,
    bg: 'var(--pink-bg)',
    borderColor: 'var(--pink-border)',
    backdropFilter: 'blur(5px)',
  },
  success: {
    ...common,
    bg: 'var(--green-bg)',
    borderColor: 'var(--green-border)',
    backdropFilter: 'blur(5px)',
  },
  blue: {
    ...common,
    bg: 'var(--blue-bg)',
    borderColor: 'var(--blue-border)',
    backdropFilter: 'blur(5px)',
  },
  packageDetailSuccess: {
    ...common,
    ...packageMsg,
  },
  packageDetailWarning: {
    ...common,
    ...packageMsg,
  },
  packageDetailInfo: {
    ...common,
    ...packageMsg,
  },
};

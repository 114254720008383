import React from 'react';
import styled from '@emotion/styled/macro';
import { Image, Flex } from 'rebass';
import { Text } from 'components/primitive/Text';
import { Link } from 'components/primitive/Link';
import DiscordLogo from 'assets/icons/discord-white.svg';
import { Divider } from 'components/primitive/Divider';
import LinkIcon from 'assets/icons/link-out-blue.svg';
import { Switch } from 'components/simple/Switch';
import { useExperimentalFeatureFlag } from 'hooks/useExperimentalFlag';
import { ROUTES } from 'routes';

export const UserId = styled(Text)`
  &.name {
    font-size: 16px;
    text-transform: capitalize;
    opacity: 1;
  }
  &.name.community,
  &.email {
    opacity: 0.7;
  }
  &.email,
  &.userTier {
    font-size: 10px;
  }
`;
export const StyledHeader = styled(Flex)`
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grey-1);
  width: 100vw;
  max-width: 100%;
  padding: 0 24px;
  z-index: 50;
  box-sizing: border-box;
  & .unknown {
    cursor: default;
  }
  & > * > a,
  & > * > * > a {
    opacity: 0;
    transform: translate(0, 10px);
    ${new Array(20)
      .fill(0)
      .map(
        (unused, i) => `
          &:nth-of-type(${i}) {
            animation: 150ms header-link-appear ${i * 50}ms 1 forwards;
          }
        `
      )
      .join('\n')};
    @keyframes header-link-appear {
      0% {
        opacity: 0;
        transform: translate(0, 10px);
      }
      100% {
        opacity: 1;
        transform: translate(0, 0px);
      }
    }
  }
`;
export const NavText = styled(Text)`
  font-family: Manrope, sans-serif;
  font-weight: 600;
  padding-left: 0.5em;
  white-space: nowrap;
`;
export const NavLink = styled(Link)`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  min-height: 30px;
  .text {
    opacity: 0.6;
    padding: 0.25em 1em 0.4em;
  }
  .locked {
    opacity: 0.25;
  }
  .iconOn,
  .iconOff {
    margin-left: 0.25em;
  }
  .iconOn {
    display: none;
  }
  :hover .text,
  :hover .locked {
    opacity: 1;
  }
  :hover .iconOn {
    display: block;
  }
  :hover .iconOff {
    display: none;
  }
`;
export const JoinDiscord = styled(Link)`
  background: transparent;
  padding: 0.6em 1.5em 0.7em 3.5em;
  border: 1px solid var(--grey-3);
  border-radius: 20px;
  margin-right: 2em;
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-weight: 600;
  background-image: url(${DiscordLogo});
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 20px;
  :hover {
    background-color: var(--grey-2);
  }
  :active {
    background-color: transparent;
  }
`;
export const LatestUpdatesButton = styled(Flex)`
  height: 42px;
  background: transparent;
  border: 1px solid #fdf7fa20;
  border-radius: 3px;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  :hover {
    background-color: var(--grey-2);
  }
  :active {
    border: 1px solid var(--blue-main);
  }
`;
export const NavWrap = styled(Flex)`
  position: relative;
  z-index: 2;
  width: 100%;
  flex-direction: column;
  margin: 0 1em 0.5em 0;
`;
export const NavItem = ({
  link,
  text,
  isExternal = false,
  hasDivider = true,
}: {
  link: string;
  text: string;
  isExternal?: boolean;
  hasDivider?: boolean;
}) => {
  return (
    <>
      {hasDivider && (
        <Divider mb={['0.5em', '0.5em', '0.5em', '0.5em', '1em']} />
      )}
      <Flex mb={[0, 0, 0, 0, '0.5em']} ml="0" alignItems="center">
        {isExternal ? (
          <NavLink _blank noopener href={link}>
            <NavText className="text">
              {text}
              <Image
                src={LinkIcon}
                alt="link out icon"
                sx={{
                  height: '13px',
                  marginLeft: '8px',
                  verticalAlign: 'middle',
                }}
              />
            </NavText>
          </NavLink>
        ) : (
          <NavLink to={link} data-test="open-github-manager-page">
            <NavText className="text">{text}</NavText>
          </NavLink>
        )}
      </Flex>
    </>
  );
};
export const UserLabel = (user: User) => {
  const { name, email, isPro, tier } = user;
  return (
    <>
      <Flex
        mr="1em"
        alignSelf="flex-end"
        flexDirection="column"
        display={['none', 'none', 'none', 'none', 'flex']}
      >
        <UserId variant="h3" className={`name ${tier}`}>
          {name}
        </UserId>
      </Flex>
      <Flex mr="1em" alignSelf="flex-end" flexDirection="column">
        <UserId variant="h5" className={`email ${tier}`}>
          {email}
        </UserId>
      </Flex>
      <Flex
        mr="1em"
        mb={['0.25em', '0.25em', '0.25em', '0.25em', '1.5em']}
        mt="0.25em"
        alignSelf="flex-end"
        flexDirection="column"
      >
        {isPro ? (
          <UserId variant="h5" className="userTier textGradient">
            Phylum Pro Account
          </UserId>
        ) : (
          <Link to={ROUTES.UPGRADE.path} hoverline={true} className={tier}>
            <UserId variant="h5" className="userTier textGradient">
              Phylum Community
            </UserId>
          </Link>
        )}
      </Flex>
    </>
  );
};

export const ToggleExperiment = ({ isPro }: User) => {
  const { flag, updateFlag } = useExperimentalFeatureFlag();
  if (isPro)
    return (
      <>
        <Divider />
        <Flex alignItems="center" pl="1em" my="0.75em">
          <Text variant="h6" fontSize="11px" color="#fdfaf780" mr="1em">
            Enable Experimental Features
          </Text>
          <Switch
            onChange={async () => {
              if (flag != 'on') {
                localStorage.setItem('experimental', 'on');
                updateFlag('on');
              } else {
                localStorage.setItem('experimental', 'off');
                updateFlag('off');
              }
            }}
            checked={flag == 'on'}
            isSmall={true}
            style={{ opacity: flag == 'on' ? 0.8 : 1 }}
          />
        </Flex>
      </>
    );
  else return <></>;
};

export type IRadiiScaleArray = Array<string | number> & {
  slight: number | string;
  button: number | string;
  card: number | string;
  chip: number | string;
  toggle: number | string;
  circle: number | string;
};

const radiiScale = [5, 10, 15, 21, 45, '100%'];
const radiiScaleObject = {
  slight: radiiScale[0],
  button: radiiScale[1],
  card: radiiScale[2],
  chip: radiiScale[3],
  toggle: radiiScale[4],
  circle: radiiScale[5],
};

export const radii: IRadiiScaleArray = Object.assign(
  radiiScale,
  radiiScaleObject
);

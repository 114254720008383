const common = {
  borderRadius: '3px',
  py: 4,
  px: 7,
};

export const cards = {
  primary: {
    ...common,
    bg: 'var(--grey-2)',
  },
  secondary: {
    ...common,
    bg: 'var(--grey-3)',
  },
  outline: {
    ...common,
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'var(--grey-3)',
  },
  log: {
    borderRadius: '1px',
    py: '0',
    px: '0.75em',
    minHeight: '35px',
  },
};

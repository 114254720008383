/**
 *  For more details, refer: https://usehooks.com/useRouter/
 */
import { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { getQueryObject } from 'utils/getQueryObject';

export const useRouter = (): IRouterValue => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...getQueryObject(location.search), // Convert string to object
        ...params,
      },
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
};

/** Provide a low level api context to child components/providers */

import React from 'react';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { useAuth } from 'hooks/useAuth';

export const ApiContext = React.createContext<AxiosInstance | undefined>(undefined);

// Provider component that wraps your app and makes api object
// available to any child component that calls useApi();
export const ApiContextProvider = ({
  apiUrl,
  children,
}: {
  apiUrl: string;
  children?: React.ReactNode;
}) => {
  const authContext = useAuth();
  const api = axios.create();

  // Request interceptor for API calls
  api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
      const accessToken = authContext.state === 'isAuthenticated' ? authContext.access_token : null;

      if (accessToken && config?.url?.startsWith(apiUrl)) {
        config.headers.setAuthorization(`Bearer ${accessToken}`);
      }

      return config;
    },

    async (error) => {
      throw error;
    }
  );

  // Response interceptor for API calls
  api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async function (e) {
      const error = e.response?.data.error as ResponseDataError;
      const apiError = error?.apiError;

      if (apiError) {
        switch (apiError?.type) {
          case 'Policy':
            return Promise.reject(apiError.details);
          case 'GithubAccountLinkRequired':
            return Promise.resolve(e.response.data);
          case 'InvalidDependencyFiles':
            return Promise.reject(apiError.details.dependency_files);
          case 'AnalysisSubmissionFailed':
            return Promise.reject('Analysis could not be started.');
          case 'NotFound':
            // prevent refetch of non-existent data
            if (e.config.url.includes('connections/snyk')) return Promise.resolve(error.reason);
            if (e.config.url.includes('packages')) return Promise.resolve(null);
            if (e.config.url.includes('subscribers/continuous_monitoring'))
              return Promise.resolve(null);
            else return Promise.reject(error.reason);
          default:
            return Promise.reject(error.reason);
        }
      } else if (axios.isAxiosError(e)) return Promise.reject(e.message);
      else return Promise.reject('Unknown Error');
    }
  );

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

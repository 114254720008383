import { ROUTES } from 'routes';

export const hintPrompt = (choice: SearchScope | undefined) => {
  if (!choice) return 'I am looking for...';
  else if (choice == 'PhylumPackages') return 'Select an ecosystem:';
  else return 'Narrow the search by:';
};

export const extractProjects = (data: UserPackage[]) => {
  const projects = data.map((userPackage) => {
    const project = {
      name: userPackage.project_name,
      id: userPackage.project_id,
      ecosystems: [userPackage.ecosystem],
      package_name: userPackage.name + '@' + userPackage.version,
    };
    return project;
  });
  return projects;
};

export const processSearchInput = (
  searchText: string,
  ecosystem: Ecosystem | null,
  searchScope: SearchScope | undefined
) => {

  // when search text starts with "@", determine whether the user typed in a version, or a namespace

  const stringArrayFromInput = searchText.split('@');
  const lastAtPosition = searchText.lastIndexOf('@');
  const mysteryString = searchText.substring(lastAtPosition + 1);

  const versionCheck =
    mysteryString[0] == 'v' ? mysteryString.substring(1) : mysteryString;

  const looksLikeNamespace =
    searchText &&
    searchText.indexOf('@') == 0 &&
    isNaN(versionCheck.split('.').reduce((sum, a) => sum + parseInt(a), 0));


  // assemble the querystring based on estimate about the user intent 

  const name = !searchText
    ? ``
    : searchText.indexOf('@') == 0
    ? stringArrayFromInput.length > 2 ||
      (stringArrayFromInput.length == 2 && looksLikeNamespace)
      ? `text=@${stringArrayFromInput[1]}`
      : ``
    : `text=${stringArrayFromInput[0]}`;

  const version = !stringArrayFromInput.length
    ? ``
    : looksLikeNamespace || searchText.indexOf('@') < 0
    ? ``
    : `version=${mysteryString}`;

  const baseQuery = name ? (version ? `${name}&${version}` : name) : version;

  const eco = ecosystem ? `ecosystem=${ecosystem}` : '';
  const ecosysQuery = baseQuery
    ? eco
      ? `${eco}&${baseQuery}`
      : baseQuery
    : eco;

  if (!searchScope && baseQuery) {
    return `${ROUTES.RESULTS_PACKAGES.path}?${baseQuery}`;
  } else if (searchScope == 'ProjectPackages' && baseQuery) {
    return `${ROUTES.RESULTS_PACKAGES.path}?scope=packages&${baseQuery}`;
  } else if (searchScope == 'PhylumPackages' && ecosysQuery) {
    return `${ROUTES.RESULTS_PHYLUM.path}?scope=phylum&${ecosysQuery}`;
  } else if (searchScope == 'Projects' && ecosysQuery) {
    return `${ROUTES.RESULTS_PROJECTS.path}?scope=projects&${ecosysQuery}`;
  } else {
    return null;
  }
};

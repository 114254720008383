import React from 'react';
import { ExperimentalFeatureContext } from 'contexts/ExperimentalFeatureContext';

export function useExperimentalFeatureFlag() {
  const context = React.useContext(ExperimentalFeatureContext);
  if (!context) {
    throw new Error('wrong place for using');
  }
  return context;
}

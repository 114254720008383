import React, { memo, useMemo, Suspense } from 'react';
import { Route, RouteProps } from 'react-router';
import { Fallback } from 'components/layout/Fallback';
import { useAuth } from 'hooks/useAuth';
import { Login } from 'views/Login';

// eslint-disable-next-line
const DynamicComponent = (component: any) => () => {
  const ComponentContainer = useMemo(() => component, [component]);
  return (
    <Suspense fallback={<Fallback />}>
      {ComponentContainer && <ComponentContainer />}
    </Suspense>
  );
};

export const AppRoute = memo(
  ({ component, exact, path }: RouteProps) => {
    const { state } = useAuth();
    const authenticated = state === 'isAuthenticated';
    const View = useMemo(() => DynamicComponent(component), [component]);
    return (
      <Route
        exact={exact}
        path={path}
        render={() => {
          return authenticated ? <View /> : <Login />;
        }}
      />
    );
  },
  (prevProp, nextProp) => prevProp.path === nextProp.path
);

import React from 'react';
import { observer } from 'mobx-react-lite';

import { AuthContextStore } from './AuthContextStore';
import { toJS } from 'mobx';

export const AuthContext = React.createContext<AuthState | undefined>(
  undefined
);
// Makes auth object available to any child component that calls useAuth()
export const AuthContextProvider = observer(
  ({
    contextStore,
    children,
  }: {
    contextStore: AuthContextStore;
    children?: React.ReactNode;
  }) => {
    const authState = toJS(contextStore.authState);
    return (
      <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
    );
  }
);

import React from 'react';
import { DimensionsContextProvider } from './DimensionsContext';
import { ApiContextProvider } from './ApiContext';
import { AuthContextProvider } from './AuthContext';
import { SWRConfigContext } from './SWRConfigContext';
import { env } from 'config/env';
import { AuthContextStore } from './AuthContextStore';
import { SearchProvider } from './SearchContext';
import { ExperimentalFlagProvider } from './ExperimentalFeatureContext';
import { ROUTES } from 'routes';

export const Contexts = ({ children }: { children?: React.ReactNode }) => {
  const [authContextStore, setAuthContextStore] =
    React.useState<AuthContextStore | null>(null);

  React.useEffect(() => {
    // TODO If we serve from a non-base folder then this will need to be updated
    const redirectUrl = new URL(
      ROUTES.LOGIN_CALLBACK.path,
      window.location.origin
    );

    async function doIt() {
      const authContextStore = await AuthContextStore.buildForOidcDiscoveryUrl(
        env.OIDC_DISCOVERY_URL,
        redirectUrl,
        env.OIDC_CLIENT_ID
      );
      setAuthContextStore(authContextStore);
    }
    doIt();
  }, []);

  if (authContextStore) {
    return (
      <AuthContextProvider contextStore={authContextStore}>
        <ApiContextProvider apiUrl={env.API_URL.toString()}>
          <SWRConfigContext>
            <SearchProvider>
              <DimensionsContextProvider>
                <ExperimentalFlagProvider>{children}</ExperimentalFlagProvider>
              </DimensionsContextProvider>
            </SearchProvider>
          </SWRConfigContext>
        </ApiContextProvider>
      </AuthContextProvider>
    );
  } else {
    return <></>;
  }
};

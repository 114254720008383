import styled from '@emotion/styled/macro';
import { Box as ReBox, BoxProps as ReBoxProps } from 'rebass';

// Our subcomponents that override box provide their own synthetic callbacks that
// do not use HTML events.
export type BoxProps = Omit<
  ReBoxProps,
  BrokenRebassProps | 'onChange' | 'onInput' | 'defaultValue' | 'value'
>;

export const Box = styled(ReBox)<BoxProps>`
  box-sizing: border-box;
`;

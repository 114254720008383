import React from 'react';
import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { Box } from 'components/primitive/Box';

const StyledLoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  height: 300px;
  overflow: hidden;
`;

const StyledLoaderBall = styled(Box)`
  flex: 0;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 100%;
  background: var(--blue-main);
  transform: translate(0, 400px);
  opacity: 0;
  ${() =>
    new Array(8).fill(0).map(
      (unused, i) => `&:nth-of-type(${i + 1}) {
        animation: 3s line-chart-loader-ball ${
          0.2 * i
        }s ease-in-out infinite running;
      }`
    )};

  @keyframes line-chart-loader-ball {
    0% {
      transform: translate(0, 400px);
      opacity: 0;
    }
    20% {
      transform: translate(0, 60px);
      opacity: 0;
    }
    40% {
      transform: translate(0, -20px);
      opacity: 1;
    }
    60% {
      transform: translate(0, 10px);
      opacity: 1;
    }
    80% {
      transform: translate(0, -60px);
      opacity: 0;
    }
    100% {
      transform: translate(0, -400px);
      opacity: 0;
    }
  }
`;

export const Loader = () => (
  <StyledLoaderContainer>
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
    <StyledLoaderBall />
  </StyledLoaderContainer>
);

import React from 'react';
import { Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import { lazyImport } from 'utils/lazyImport';
import { AppRoute } from 'components/layout/AppRoute';
import { currentUser } from 'utils/currentUser';

const { GroupsLocked } = lazyImport(
  () => import('views/Upgrade/GroupsLocked'),
  'GroupsLocked'
);
const { EventsLocked } = lazyImport(
  () => import('views/Upgrade/EventsLocked'),
  'EventsLocked'
);
const { ContactSales } = lazyImport(
  () => import('views/Upgrade/ContactSales'),
  'ContactSales'
);

export const Router = () => {
  const { push } = useHistory();
  currentUser().isPro && push(ROUTES.GROUPS.path);
  return (
    <Switch>
      <AppRoute path={ROUTES.UPGRADE_GROUPS.path} component={GroupsLocked} />
      <AppRoute path={ROUTES.UPGRADE_EVENTS.path} component={EventsLocked} />
      <AppRoute
        path={[ROUTES.UPGRADE.path, ROUTES.UPGRADE_NO_AUTH.path]}
        component={ContactSales}
      />
    </Switch>
  );
};

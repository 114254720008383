import React from 'react';
import { ContactSales } from 'views/Upgrade/ContactSales';
import { PageContent } from '../App/components/PageContent';

export const ContactSalesUnauthenticated = () => {
  return (
    <PageContent>
      <ContactSales />
    </PageContent>
  );
};

import React from 'react';

export const Callback = (props: { authState: AuthState }): JSX.Element => {
  React.useEffect(() => {
    if (props.authState.state === 'needAuthorization') {
      const callbackUrl = new URL(window.location.href);
      props.authState.acknowledge(callbackUrl.toString());
    }
  }, [props.authState]);

  return <></>;
};

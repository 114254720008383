import { KeyboardEvent } from 'react';

export const onKeyboardSubmit = (
  callback: CallableFunction,
  keys?: string[]
) => (e: KeyboardEvent): void => {
  if ((keys || ['Enter']).includes(e.key)) {
    if (e.nativeEvent) {
      e.nativeEvent.preventDefault();
    } else {
      e.preventDefault();
    }
    callback();
  }
};

import { env } from 'config/env';

const BASE = env.API_URL;
const API = BASE + 'api/v0' || 'http://127.0.0.1:8010';
const DATA = API + '/data';

export const ROUTES = {
  INDEX: {
    path: '/',
    public: true,
  },
  PACKAGE_DETAILS: {
    path: '/package/:registry/:packageId/:version',
    public: true,
  },
  PACKAGE_NO_VERSION: {
    path: '/package/:registry/:packageId',
    public: true,
  },
  PROJECTS: {
    path: '/projects',
    public: false,
  },
  PROJECT: {
    path: '/projects/:projectId',
    public: false,
  },
  PROJECT_PREFERENCES: {
    path: '/projects/:projectId/settings',
    public: false,
  },
  PROJECT_POLICY: {
    path: '/projects/:projectId/policy',
    public: false,
  },
  PROJECT_GRAPH_TESTING: {
    path: '/projects/:projectId/graph',
    public: false,
  },
  DASHBOARD: {
    path: '/dashboard',
    public: false,
  },
  RESULTS: {
    path: '/results/:category',
    public: false,
  },
  RESULTS_PACKAGES: {
    path: '/results/packages',
    public: false,
  },
  RESULTS_PROJECTS: {
    path: '/results/projects',
    public: false,
  },
  RESULTS_PHYLUM: {
    path: '/results/phylum',
    public: false,
  },
  EVENT_LOGS: {
    path: '/events',
    public: false,
  },
  SETTINGS: {
    path: '/settings',
    public: false,
  },
  QUICK: {
    path: '/quick',
    public: false,
  },
  QUICKSTART: {
    path: '/quickstart',
    public: false,
  },
  QUICK_CLI: {
    path: '/quickstart/cli',
    public: false,
  },
  QUICK_DEFENSE: {
    path: '/quickstart/defense',
    public: false,
  },
  QUICK_GITHUB: {
    path: '/quickstart/github',
    public: false,
  },
  QUICK_GITLAB: {
    path: '/quickstart/gitlab',
    public: false,
  },
  AUTH: {
    path: '/auth',
    public: true,
  },
  LOGIN: {
    path: '/auth/login/:idp?',
    public: true,
  },
  LOGIN_CALLBACK: {
    path: '/auth/callback',
    public: true,
  },
  LOGIN_ERROR: {
    path: '/auth/error',
    public: true,
  },
  REGISTER: {
    path: '/register',
    public: true,
  },
  EXTERNAL: {
    CONTACT: 'https://phylum.io/contact',
    DOCS: 'https://docs.phylum.io',
    BLOG: 'https://blog.phylum.io',
  },
  GITHUB_APP_SETTINGS: {
    path: '/github',
    public: false,
  },
  GITHUB_APP_INSTALLATION: {
    path: '/github/:installationId',
    public: false,
  },
  GROUPS: {
    path: '/groups',
    public: false,
  },
  GROUP: {
    path: '/groups/:groupId',
    public: false,
  },
  GROUP_USERS: {
    path: '/groups/:groupId/users',
    public: false,
  },
  GROUP_PROJECTS: {
    path: '/groups/:groupId/projects',
    public: false,
  },
  GROUP_PREFERENCES: {
    path: '/groups/:groupId/preferences',
    public: false,
  },
  GROUP_POLICY: {
    path: '/groups/:groupId/policy',
    public: false,
  },
  LOCKSMITH_CALLBACK: {
    path: '/tokens/callback',
    public: false,
  },
  LOCKSMITH_TOKENS: {
    path: '/tokens/list',
    public: false,
  },
  LOCKSMITH_INIT: {
    path: '/tokens/init',
    public: false,
  },
  LOCKSMITH: {
    path: '/tokens',
    public: false,
  },
  UPGRADE: {
    path: '/upgrade',
    public: true,
  },
  UPGRADE_NO_AUTH: {
    path: '/upgrade-cli',
    public: true,
  },
  UPGRADE_GROUPS: {
    path: '/upgrade/groups',
    public: false,
  },
  UPGRADE_EVENTS: {
    path: '/upgrade/events',
    public: false,
  },
  API: {
    JOBS: `${DATA}/jobs`,
    PACKAGES: `${DATA}/packages`,
    PROJECT_DATA: `${DATA}/projects`,

    GITHUB_INSTALLATIONS: `${API}/github/installations`,
    GROUPS: `${API}/groups`,
    GROUP_PREFERENCES: `${API}/preferences/group/`,
    INVITE: `${API}/invite`,
    EVENTS: `${API}/audit-events`,
    PHYLUM_POLICIES: `${API}/available-policies`,
    PROJECT_PREFERENCES: `${API}/preferences/project/`,
    PROJECTS: `${API}/projects`,
    SEARCH_FULL: `${API}/search/full`,
    SEARCH_QUICK: `${API}/search/quick`,
    THREATS: `${API}/threats`,

    LOCKSMITH: `${BASE}locksmith/v1/`,
    RECOMMENDER: `${BASE}recommendation`,
  },
};

// Generate various oauth related urls

import axios from 'axios';

// Scopes
// openid = use Open Id Connect
// offline = return refresh token
// profile = return profile data
const scopes = ['openid', 'offline_access', 'profile', 'email'];

/**
 * Query the Oidc Identity Provider settings url and return the information we
 * need
 *
 * @param oidcDiscoveryUrl the oidc discovery url to query
 * @returns A subset of the data, the urls we are interested in
 */
export async function fetchOidcIdentityProviderSettings(
  oidcDiscoveryUrl: URL
): Promise<IdentityProviderSettings> {
  const response = await axios.get<IdentityProviderSettings>(
    oidcDiscoveryUrl.toString()
  );
  if (response.status == 200) {
    return response.data;
  } else {
    throw new Error(
      `Could not load identity provider settings from '${oidcDiscoveryUrl}', check your settings`
    );
  }
}

/**
 * Build the full GET url for the User Registration endpoint, including the
 * proper query params as part of the url.
 *
 * @param authUrl The authorization url obtained from the
 * {@link IdentityProviderSettings.authorization_endpoint}
 * @param oidcClientId The id for this authentication client
 * @param codeChallenge The code challenge to embed in the url
 * @param state The state value to embed in the url
 * @returns The full authorize endpoint url, with the /auth path transformed to
 * /registrations
 */
export function buildUserRegistrationUrl(
  authUrl: URL,
  redirectUrl: URL,
  oidcClientId: string,
  codeChallenge: string,
  state: string
): URL {
  const urlParams = new URLSearchParams();
  urlParams.append('client_id', oidcClientId);
  urlParams.append('code_challenge', codeChallenge);
  urlParams.append('code_challenge_method', 'S256');
  // TODO: Get full path
  urlParams.append('redirect_uri', redirectUrl.toString());
  urlParams.append('response_type', 'code');
  // Code will be appended to redirect_uri as fragment
  urlParams.append('response_mode', 'fragment');
  urlParams.append('scope', scopes.join(' '));
  urlParams.append('state', state);
  // urlParams.append('prompt', 'login');
  const registrationUrl = authUrl
    .toString()
    .replace('openid-connect/auth', 'openid-connect/registrations');
  return new URL(`?${urlParams.toString()}`, registrationUrl);
}

/**
 * Build the logout url, including the
 * proper query params as part of the url.
 *
 * @param logoutUrl The authorization url obtained from the
 * {@link IdentityProviderSettings.end_session_endpoint}
 * @param oidcClientId The id for this authentication client
 * @returns The full logout endpoint url
 */
export function buildLogoutUrl(
  logoutUrl: URL,
  postLogoutRedirectUrl: URL,
  oidcClientId: string,
  idTokenHint: string
): URL {
  const urlParams = new URLSearchParams();
  urlParams.append('client_id', oidcClientId);
  urlParams.append(
    'post_logout_redirect_uri',
    postLogoutRedirectUrl.toString()
  );
  urlParams.append('id_token_hint', idTokenHint);
  return new URL(`?${urlParams.toString()}`, logoutUrl);
}

/**
 * Build the full GET url for the Authorization endpoint, including the proper
 * query params as part of the url.
 *
 * @param authUrl The authorization url obtained from the
 * {@link IdentityProviderSettings.authorization_endpoint}
 * @param oidcClientId The id for this authentication client
 * @param codeChallenge The code challenge to embed in the url
 * @param state The state value to embed in the url
 * @returns The full authorize endpoint url
 */
export function buildAuthorizationUrl(
  authUrl: URL,
  redirectUrl: URL,
  oidcClientId: string,
  codeChallenge: string,
  state: string
): URL {
  const urlParams = new URLSearchParams();
  urlParams.append('client_id', oidcClientId);
  urlParams.append('code_challenge', codeChallenge);
  urlParams.append('code_challenge_method', 'S256');
  // TODO: Get full path
  urlParams.append('redirect_uri', redirectUrl.toString());
  urlParams.append('response_type', 'code');
  // Code will be appended to redirect_uri as fragment
  urlParams.append('response_mode', 'fragment');
  urlParams.append('scope', scopes.join(' '));
  urlParams.append('state', state);
  // urlParams.append('prompt', 'login');
  return new URL(`?${urlParams.toString()}`, authUrl);
}

/**
 * build the data payload that must be posted to the
 * {@link IdentityProviderSettings.token_endpoint} url endpoint the first time
 * to obtain tokens, including refresh tokens.
 *
 * When using PKCE the first request to /authorize will return an authorization
 * code.
 *
 * This code must then be used to fetch tokens the first time. These tokens will
 * include a refresh token that then must be used with
 * {@link buildTokenUrlGrantTypeRefreshToken} to renew tokens from that point
 * forward.
 *
 * @param oidcClientId The id of the client making the request
 * @param authorizationCode The authorization code obtained from the
 * authorization step
 * @param codeVerifier The code verifier that was used to generate the code
 * challenge in the authorization step
 * @returns The data that needs to be sent as a post body to the token url
 */
export function buildTokenUrlPostDataGrantTypeAuthorizationCode(
  redirectUrl: URL,
  oidcClientId: string,
  authorizationCode: string,
  codeVerifier: string
): URLSearchParams {
  const urlParams = new URLSearchParams();
  urlParams.append('client_id', oidcClientId);
  urlParams.append('code', authorizationCode);
  urlParams.append('code_verifier', codeVerifier);
  urlParams.append('grant_type', 'authorization_code');
  // urlParams.append('code_challenge_method', 'S256');
  // TODO: Get full path
  // Must match previous request to /authorize, but not actually redirect to by
  // the auth server.
  urlParams.append('redirect_uri', redirectUrl.toString());
  return urlParams;
}

/**
 * build the data payload that must be posted to the
 * {@link IdentityProviderSettings.token_endpoint} url endpoint as part of the
 * ongoing refresh token flow to renew access tokens
 *
 * @param oidcClientId The id of the client making the request
 * @param refreshtoken The refresh token used to renew access tokens
 * @returns The data that needs to be sent as a post body to the token url
 */
export function buildTokenUrlPostDataGrantTypeRefreshToken(
  oidcClientId: string,
  refreshToken: string
): URLSearchParams {
  const urlParams = new URLSearchParams();
  urlParams.append('client_id', oidcClientId);
  urlParams.append('grant_type', 'refresh_token');
  urlParams.append('refresh_token', refreshToken);
  urlParams.append('scope', scopes.join(' '));
  return urlParams;
}

import React from 'react';
import styled from '@emotion/styled/macro';
import { Flex, FlexProps } from 'rebass';
import { Box } from 'components/primitive/Box';
import { ROUTES } from 'routes';
import { NavTabs } from 'components/layout/NavTabs';
import { currentUser } from 'utils/currentUser';

export type NavProps = Omit<FlexProps, BrokenRebassProps>;

const buildNavLinks = (isPro = false) => [
  {
    url: ROUTES.DASHBOARD.path,
    route: [ROUTES.DASHBOARD.path, ...[ROUTES.INDEX.path]],
    label: 'Dashboard',
    exact: true,
    strict: true,
    index: true,
  },
  {
    url: ROUTES.PROJECTS.path,
    route: [ROUTES.PROJECTS.path],
    label: 'Projects',
    exact: true,
    strict: true,
    index: true,
  },
  {
    hide: !isPro,
    url: ROUTES.GROUPS.path,
    route: [ROUTES.GROUPS.path, ...[]],
    label: 'Groups',
    exact: false,
    strict: false,
    index: false,
  },
  {
    hide: !isPro,
    url: ROUTES.EVENT_LOGS.path,
    route: [ROUTES.EVENT_LOGS.path, ...[]],
    label: 'Event Logs',
    exact: false,
    strict: false,
    index: false,
  },
  {
    hide: isPro,
    url: ROUTES.UPGRADE_GROUPS.path,
    route: [ROUTES.UPGRADE_GROUPS.path, ...[]],
    label: 'Groups',
    exact: false,
    strict: false,
    index: false,
  },
  {
    hide: isPro,
    url: ROUTES.UPGRADE_EVENTS.path,
    route: [ROUTES.UPGRADE_EVENTS.path, ...[]],
    label: 'Event Logs',
    exact: false,
    strict: false,
    index: false,
  },
];
const StyledNav = styled(Flex)`
  position: sticky;
  z-index: 45;
  align-items: center;
  height: 2.75em;
  width: 100vw;
  min-height: 2.75em;
  min-width: 100%;
  max-height: 2.75em;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: var(--grey-2);
`;

export const Nav = React.memo((props: FlexProps) => {
  const isPro = currentUser().isPro;
  // const isWIP = currentUser().isWIP;

  return (
    <StyledNav as="nav" {...props} id="nav-container">
      <Box
        overflow="auto"
        width={[
          'calc(40em)',
          'calc(40em)',
          'calc(40em)',
          'calc(54em)',
          'calc(77em)',
          'calc(82.5em)',
        ]}
        sx={{
          mx: 'auto',
          maxWidth: '100%',
          top: ['72px', '72px', '72px', '72px', '100px'],
        }}
      >
        <NavTabs options={buildNavLinks(isPro)} indexUrl={ROUTES.INDEX.path} />
      </Box>
    </StyledNav>
  );
});

import { typography } from '../typography';

const common = {
  ...typography.text.body2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  bg: 'transparent',
  width: '100%',
  color: 'text.primary',
  transition: '200ms linear',
  outline: 'none',
  fontWeight: 'normal',
  border: 'none',
  '::placeholder': {
    color: 'text.secondary',
  },
  ':focus': {
    outline: 'none',
    border: 'none',
    color: 'text.primary',
  },
};

const commonContainer = {
  py: '0.5em',
  px: '1em',
  borderRadius: '3px',
  display: 'inline-flex',
  alignItems: 'center',
  transition: '200ms linear',
  '& img': {
    height: '28px',
  },
};

export const inputs = {
  codeSnippet: {
    ...common,
    fontWeight: 400,
    ':focus': {
      color: 'var(--green-highlight)',
      opacity: 0.8,
    },
  },
  codeSnippetContainer: {
    ...commonContainer,
    py: '0.3em',
    border: '1px solid',
    borderColor: 'var(--grey-1)',
    backgroundColor: 'var(--grey-1)',
    ':hover': {
      borderColor: 'var(--grey-2)',
    },
  },
  outline: {
    ...common,
  },
  outlineContainer: {
    ...commonContainer,
    bg: 'var(--grey-1)',
    border: '1px solid',
    borderColor: 'var(--grey-3)',
    ':focus-within': {
      outline: 'none',
      bg: 'var(--grey-1)',
      '& img': {
        opacity: 0.8,
      },
    },
    ':focus': {
      bg: 'var(--grey-4)',
    },
    ':hover': {
      bg: 'var(--grey-1)',
      '& img': {
        opacity: 1,
      },
    },
  },
  clearIcon: {
    flexShrink: 0,
    ml: 2,
    transform: 'translateY(-1px)',
  },
  searcheable: {
    ...common,
    transition: 'unset',
    fontWeight: 400,
  },
  searcheableContainer: {
    ...commonContainer,
    transition: 'unset',
    pr: 0,
    pl: '0.25em',
    py: '1px',
    bg: 'transparent',
    ':focus-within': {
      outline: 'none',
    },
    ':focus': {
      bg: 'var(--grey-4)',
    },
    width: 'inherit',
  },
};
